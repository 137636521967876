import React from "react";

const PanelInformativoConta = ({ array, setPanel, tipoFiltro }) => {
	let suma = 0;
	array.forEach(function (value, key) {
		suma += value;
	});
	return (
		<div
			className='callout alert'
			style={{
				display: "block",
				position: "fixed",
				right: "0%",
				bottom: "50%",
				zIndex: "99",
				boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75)",
			}}
			data-closable
		>
			El Saldo de {tipoFiltro === "Todos" && "todos"} los clientes{" "}
			{tipoFiltro === "Vigente" && "vigentes"}{" "}
			{tipoFiltro === "Con DICOM" && "con DICOM"}{" "}
			{tipoFiltro === "Con Riesgo" && "con Riesgo"}{" "}
			{tipoFiltro === "No Vigente" && "no vigentes"} en Conta es:&nbsp;
			{new Intl.NumberFormat("es-CL", {
				currency: "CLP",
				style: "currency",
			}).format(suma)}
			&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
			<br />
			Actualmente hay un total de {array.size} clientes en este listado de
			Conta.
			<button
				className='close-button'
				aria-label='Dismiss alert'
				type='button'
				data-close
				onClick={(e) => setPanel(false)}
			>
				<span aria-hidden='true'>&times;</span>
			</button>
		</div>
	);
};

export default PanelInformativoConta;
