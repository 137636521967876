import React from "react";
import ListaClientesContaUsuarioNormal from "./ListaClientesContaUsuarioNormal";

//Esta genera el listado de empresas, en función de la busqueda realizada.

const ResultadoBusqueda = ({ clienteContaBuscado }) => {
	return (
		<div className='column'>
			<ListaClientesContaUsuarioNormal
				clienteContaBuscado={clienteContaBuscado}
			/>
		</div>
	);
};

export default ResultadoBusqueda;
