import React from "react";
import ReactDOM from "react-dom";
import Layout from "./Layout";
import "foundation-sites/dist/css/foundation.min.css";

ReactDOM.render(
	<React.StrictMode>
		<Layout />
	</React.StrictMode>,
	document.getElementById("root")
);
