import React, { useContext, useState } from "react";
import { Context } from "../../../../store/AppContext.js";
import "../../../../../styles/TablaClientes.css";
import PanelInformativoDt from "./PanelInformativoDt.js";

const ListaClientesDtCompleta = ({
	setClienteSeleccionado,
	setClienteDtCliqueado,
	colores,
	tipoFiltro,
}) => {
	const { store, actions } = useContext(Context);
	const array = new Map();
	const [panel, setPanel] = useState(false);

	const HandlerSaldo = () => {
		setPanel(true);

		setTimeout(() => {
			setPanel(false);
		}, 10000);
	};

	const HandlerClick = (object, event) => {
		if (event.target.className != "telefono") {
			setClienteDtCliqueado(object);
			setClienteSeleccionado(true);
		}
	};

	//Funciones para el Saldo
	const CalculoSaldo = (objeto, meses) => {
		//Hay que rehacer esta función, tiene varios elementos que ya NO se utilizan y están malos
		const SaldoDeberia = (objeto, meses = false) => {
			//Este saca la cantidad de meses y su valor, desde la fecha de Contratación. Es el DEBERIA
			const fecha = new Date(objeto.fechaContratacion);
			const fechaHoy = new Date("12 31, 2022 23:59:59");
			let diferenciaMeses =
				fechaHoy.getMonth() -
				fecha.getMonth() +
				12 * (fechaHoy.getFullYear() - fecha.getFullYear());
			/* if (fechaHoy.getDate() < fecha.getDate()) {
					diferenciaMeses = diferenciaMeses - 1;
				} */
			if (diferenciaMeses >= 0) {
				return diferenciaMeses.toString();
			} else {
				return 0;
			}
		};

		const SaldoTotal = (objeto, meses) => {
			if (meses == false) {
				if (objeto.mesesPagados != null) {
					let mesesPagados = parseInt(objeto.mesesPagados);
					return mesesPagados;
				} else {
					let mesesPagados = 0;
					return mesesPagados;
				}
			}
		};

		const SaldoDeberia2023 = (objeto, meses = false) => {
			//Este saca la cantidad de meses y su valor, desde la fecha de Contratación. Es el DEBERIA
			const fecha = new Date("12 01, 2022 00:00:01");
			const fechaHoy = new Date();
			let diferenciaMeses =
				fechaHoy.getMonth() -
				fecha.getMonth() +
				12 * (fechaHoy.getFullYear() - fecha.getFullYear());
			/* if (fechaHoy.getDate() < fecha.getDate()) {
					diferenciaMeses = diferenciaMeses - 1;
				} */
			if (meses == true) {
				return (diferenciaMeses * 9900).toString();
			}
			return diferenciaMeses.toString();
		};

		const SaldoTotal2023 = (objeto, meses) => {
			//Este recorre los años y saca los montos Pagados. Es el REAL.
			if (objeto.vigente === "Si") {
				if (meses == false) {
					if (objeto.mesesPagados2023 != null) {
						let mesesPagados = parseInt(objeto.mesesPagados2023);
						return mesesPagados;
					} else {
						let uno = 0;
						return uno;
					}
				}
			} else if (objeto.vigente === "No") {
				if (meses == false) {
					if (objeto.mesesPagados2023 != null) {
						let mesesPagados2023 = parseInt(objeto.mesesPagados2023);
						return mesesPagados2023;
					} else {
						let uno = 0;
						return uno;
					}
				}
			}
		};

		const saldoCliente =
			(parseInt(SaldoDeberia(objeto, false)) -
				parseInt(SaldoTotal(objeto, false))) *
				9900 +
				(parseInt(SaldoDeberia2023(objeto, false)) -
					parseInt(SaldoTotal2023(objeto, false))) *
					11990 >=
			0
				? (parseInt(SaldoDeberia(objeto, false)) -
						parseInt(SaldoTotal(objeto, false))) *
						9900 +
				  (parseInt(SaldoDeberia2023(objeto, false)) -
						parseInt(SaldoTotal2023(objeto, false))) *
						11990
				: 0;

		if (tipoFiltro === "Todo") {
			if (objeto.vigente === "No") {
				if (objeto.sacar === "Si") {
					array.set(objeto.id, 0);
				} else {
					array.set(objeto.id, saldoCliente);
				}
			} else {
				array.set(objeto.id, saldoCliente);
			}
		} else if (tipoFiltro === "Con Deuda") {
			if (saldoCliente > 0) {
				if (objeto.vigente === "No") {
					if (objeto.sacar === "Si") {
						array.set(objeto.id, 0);
					} else {
						array.set(objeto.id, saldoCliente);
					}
				} else {
					array.set(objeto.id, saldoCliente);
				}
			}
		} else if (tipoFiltro === "Sin Deuda") {
			if (saldoCliente <= 0) {
				if (objeto.vigente === "No") {
					if (objeto.sacar === "Si") {
						array.set(objeto.id, 0);
					} else {
						array.set(objeto.id, saldoCliente);
					}
				} else {
					array.set(objeto.id, saldoCliente);
				}
			}
		} else if (tipoFiltro === "Vigente") {
			if (objeto.vigente === "Si") {
				array.set(objeto.id, saldoCliente);
			}
		} else if (tipoFiltro === "No Vigente") {
			if (objeto.vigente === "No") {
				if (objeto.sacar === "Si") {
					array.set(objeto.id, 0);
				} else {
					array.set(objeto.id, saldoCliente);
				}
			}
		} else if (tipoFiltro === "Con Patente") {
			if (objeto.patente === "Si") {
				if (objeto.vigente === "No") {
					if (objeto.sacar === "Si") {
						array.set(objeto.id, 0);
					} else {
						array.set(objeto.id, saldoCliente);
					}
				} else {
					array.set(objeto.id, saldoCliente);
				}
			}
		} else if (tipoFiltro === "Sin Patente") {
			if (objeto.patente === "No") {
				if (objeto.vigente === "No") {
					if (objeto.sacar === "Si") {
						array.set(objeto.id, 0);
					} else {
						array.set(objeto.id, saldoCliente);
					}
				} else {
					array.set(objeto.id, saldoCliente);
				}
			}
		} else if (tipoFiltro === "Con DICOM") {
			if (objeto.dicom === "Si") {
				if (objeto.vigente === "No") {
					if (objeto.sacar === "Si") {
						array.set(objeto.id, 0);
					} else {
						array.set(objeto.id, saldoCliente);
					}
				} else {
					array.set(objeto.id, saldoCliente);
				}
			}
		} else if (tipoFiltro === "Sin DICOM") {
			if (objeto.dicom === "No") {
				if (objeto.vigente === "No") {
					if (objeto.sacar === "Si") {
						array.set(objeto.id, 0);
					} else {
						array.set(objeto.id, saldoCliente);
					}
				} else {
					array.set(objeto.id, saldoCliente);
				}
			}
		} else if (tipoFiltro === "Contrato Actualizado") {
			if (objeto.contratoActualizado === "Si") {
				if (objeto.vigente === "No") {
					if (objeto.sacar === "Si") {
						array.set(objeto.id, 0);
					} else {
						array.set(objeto.id, saldoCliente);
					}
				} else {
					array.set(objeto.id, saldoCliente);
				}
			}
		} else if (tipoFiltro === "Contrato No Actualizado") {
			if (objeto.contratoActualizado === "No") {
				if (objeto.vigente === "No") {
					if (objeto.sacar === "Si") {
						array.set(objeto.id, 0);
					} else {
						array.set(objeto.id, saldoCliente);
					}
				} else {
					array.set(objeto.id, saldoCliente);
				}
			}
		} else {
			console.log("hola desde Else");
		}

		if (objeto.vigente == "No") {
			if (objeto.sacar == "Si") {
				let uno = 0;
				return uno;
			}
		}

		if (
			parseInt(SaldoDeberia(objeto, false)) +
				parseInt(SaldoDeberia2023(objeto, false)) -
				parseInt(SaldoTotal(objeto, false)) -
				parseInt(SaldoTotal2023(objeto, false)) <
			0
		) {
			return "Pagado";
		} else {
			return saldoCliente > 2090 ? saldoCliente : "Pagado";
		}
	};

	const Filtro = (objeto) => {
		// Esta función permite Filtrar el Listado de Clientes, por tipo Filtro.
		if (tipoFiltro === "Todo") {
			return objeto;
		} else if (tipoFiltro === "Con Deuda") {
			if (CalculoSaldo(objeto, false) > 0 || CalculoSaldo(objeto, false) < 0) {
				return objeto;
			}
		} else if (tipoFiltro === "Sin Deuda") {
			if (
				CalculoSaldo(objeto, false) === 0 ||
				CalculoSaldo(objeto, false) === "Pagado"
			) {
				return objeto;
			}
		} else if (tipoFiltro === "Vigente") {
			if (objeto.vigente === "Si") {
				return objeto;
			}
		} else if (tipoFiltro === "No Vigente") {
			if (objeto.vigente === "No") {
				return objeto;
			}
		} else if (tipoFiltro === "Con DICOM") {
			if (objeto.dicom === "Si") {
				return objeto;
			}
		} else if (tipoFiltro === "Sin DICOM") {
			if (objeto.dicom === "No") {
				return objeto;
			}
		} else if (tipoFiltro === "Con Patente") {
			if (objeto.patente === "Si") {
				return objeto;
			}
		} else if (tipoFiltro === "Sin Patente") {
			if (objeto.patente === "No") {
				return objeto;
			}
		} else if (tipoFiltro === "Contrato Actualizado") {
			if (objeto.contratoActualizado === "Si") {
				return objeto;
			}
		} else if (tipoFiltro === "Contrato No Actualizado") {
			if (objeto.contratoActualizado === "No") {
				return objeto;
			}
		}
	};

	// Funciones para los Colores
	const Colores = (objeto) => {
		if (colores === true) {
			if (objeto.p === "Si") {
				return "aqua";
			}
			if (objeto.vigente === "No") {
				return "red";
			}
			if (
				objeto.vigente === "Si" &&
				(CalculoSaldo(objeto, false) === 0 ||
					CalculoSaldo(objeto, false) === "Pagado")
			) {
				return "lawnGreen";
			}
		}
	};

	//La siguiente funcion despliega la lista de Clientes con sus respectivas Columnas.
	const ListaDesplegarClientes = (objeto, i) => {
		return (
			<tr
				className='trVistaAmpliada'
				key={i}
				onClick={(e) => HandlerClick(objeto, e)}
				style={colores ? { backgroundColor: Colores(objeto) } : null}
			>
				<td>{objeto.id}</td>
				<td>{objeto.razon}</td>
				<td>{objeto.rut}</td>
				<td>
					{objeto.correo}
					{objeto.correoSecundario === "" ? null : (
						<span>, {objeto.correoSecundario}</span>
					)}
					{objeto.correoTerciario === "" ? null : (
						<span>, {objeto.correoTerciario}</span>
					)}
				</td>
				<td className='telefono' style={{ cursor: "copy" }}>
					{objeto.fono}
				</td>
				<td>{objeto.fechaContratacion}</td>
				<td>{objeto.dicom}</td>
				<td>{objeto.patente}</td>
				<td>{objeto.vigente}</td>
				<td>{objeto.contratoActualizado}</td>
				{<td>${CalculoSaldo(objeto, false)}</td>}
			</tr>
		);
	};

	return (
		<div className={store.witch ? "" : "grid-x grid-margin-x"}>
			<button className='clear button alert' onClick={(e) => HandlerSaldo(e)}>
				Ver Algunos Datos
			</button>
			{panel && (
				<PanelInformativoDt
					array={array}
					setPanel={setPanel}
					tipoFiltro={tipoFiltro}
				/>
			)}
			{sessionStorage.getItem("listadoClientesDt") != null ? (
				<table
					className={`table hover ${store.witch ? "tablaClientes" : ""}`}
					id='tblData'
				>
					<thead>
						<tr>
							<th className='id' scope='col'>
								Id
							</th>
							<th scope='col'>Razon Social</th>
							<th className='rut' scope='col'>
								Rut
							</th>
							<th scope='col'>Correo</th>
							<th className='telefono' scope='col'>
								Teléfono
							</th>
							<th className='fecha' scope='col'>
								Fecha
							</th>
							<th scope='col'>DICOM</th>
							<th scope='col'>Patente</th>
							<th scope='col'>Vigente</th>
							<th scope='col'>Contrato Actualizado</th>
							<th className='saldo' scope='col'>
								Saldo
							</th>
						</tr>
					</thead>
					<tbody>
						{sessionStorage.getItem("listadoClientesDt") != null ? (
							JSON.parse(sessionStorage.getItem("listadoClientesDt"))
								.filter((objeto) => Filtro(objeto))
								.map((objeto, i) => ListaDesplegarClientes(objeto, i))
						) : (
							<td colSpan='9' style={{ height: "100px", padding: "20px" }}>
								<h2 className='text-center'> - no hay datos -</h2>
							</td>
						)}
					</tbody>
				</table>
			) : null}
		</div>
	);
};

export default ListaClientesDtCompleta;
