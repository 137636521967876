import { useEffect, useContext, useState } from "react";
import { Context } from "../../store/AppContext";
import FormularioIngresoDatos from "./IngresoDatos/FormularioIngresoDatos";
import ListaVendedores from "./IngresoDatos/ListaVendedores";

const IngresoDatos = ({ ingresoDatos, setIngresoDatos }) => {
	const { store, actions } = useContext(Context);
	const [listaVendedores, setListaVendedores] = useState([]);
	const [listaVendedoresNombre, setListaVendedoresNombre] = useState([]);
	const [datos, setDatos] = useState({
		yearIngreso: "",
		mesIngreso: "",
		totalPosibles: "",
		gastoAds: "",
		sueldoBase: "",
	});
	const [alert, setAlert] = useState(false);
	const [alertVendedor, setAlertVendedor] = useState(false);

	const HandlerCerrar = () => {
		setIngresoDatos(false);
	};

	const HandlerCrearNuevoMes = () => {
		const sleep = (ms) => {
			return new Promise((resolve) => setTimeout(resolve, ms));
		};

		const getNumFruit = (fruit, fruitNombre) => {
			return sleep(2000).then((v) =>
				actions.crearVentaEspecifica(
					datos.yearIngreso,
					datos.mesIngreso,
					"[]",
					0,
					0,
					0,
					datos.gastoAds,
					datos.totalPosibles,
					0,
					0,
					0,
					datos.sueldoBase,
					0,
					0,
					fruit,
					fruitNombre
				)
			);
		};

		const forLoop = async (_) => {
			if (listaVendedores.length === 0) {
				setAlertVendedor(true);
			}

			if (
				[
					datos.yearIngreso,
					datos.mesIngreso,
					datos.totalPosibles,
					datos.gastoAds,
					datos.sueldoBase,
				].includes("")
			) {
				setAlert(true);
			}

			if (
				listaVendedores.length != 0 &&
				![
					datos.yearIngreso,
					datos.mesIngreso,
					datos.totalPosibles,
					datos.gastoAds,
					datos.sueldoBase,
				].includes("")
			) {
				setAlert(false);
				setAlertVendedor(false);
				actions.setSpinner();

				for (let index = 0; index < listaVendedores.length; index++) {
					const fruit = listaVendedores[index];
					const fruitNombre = listaVendedoresNombre[index];
					const numFruit = await getNumFruit(fruit, fruitNombre);
				}

				actions.setSpinner();
				setTimeout(() => {
					setIngresoDatos(false);
				}, 500);
			}
		};

		forLoop();
	};

	useEffect(() => {
		actions.getUsuarios();
	}, []);

	return (
		<div className='row'>
			<div className='callout' data-closable>
				<button
					className='close-button'
					aria-label='Dismiss alert'
					type='button'
					data-close
					onClick={(e) => HandlerCerrar(e)}
				>
					<span aria-hidden='true'>×</span>
				</button>
				<br />
				<div className='grid-x'>
					<div className='cell small-6'>
						<h3>Ingreso Información</h3>
						<small>
							Con esta opción los vendedores podrán agregar información a un
							nuevo mes. Debes seleccionar los vendedores que este mes
							comisionarán, y adicionalmente agregar algunos datos del mes
							pasado.
						</small>
					</div>
				</div>
				<br />
				<ListaVendedores
					listaVendedores={listaVendedores}
					setListaVendedores={setListaVendedores}
					listaVendedoresNombre={listaVendedoresNombre}
					setListaVendedoresNombre={setListaVendedoresNombre}
				/>
				{alertVendedor && (
					<>
						<br />
						<div className='callout alert'>
							<p>Debes elegir al menos 1 vendedor que comisionará</p>
						</div>
					</>
				)}
				<br />
				<FormularioIngresoDatos datos={datos} setDatos={setDatos} />
				<br />
				{alert && (
					<>
						<div className='callout alert'>
							<h5>Todos los Datos deben ser completados</h5>
						</div>
						<br />
					</>
				)}
				<button className='button' onClick={(e) => HandlerCrearNuevoMes(e)}>
					{" "}
					Crear nuevo Mes{" "}
				</button>
			</div>
		</div>
	);
};

export default IngresoDatos;
