import { useContext, useEffect, useState } from "react";
import { Context } from "../../../store/AppContext";

const OpcionesModificarIngresoDatos = ({
	yearIngreso,
	setYearIngreso,
	mesIngreso,
	setMesIngreso,
	alert,
	setAlert,
}) => {
	const { store, actions } = useContext(Context);
	const HandlerBuscarVentasUsuario = () => {
		if (mesIngreso === "Selecciona..." && yearIngreso === "Selecciona...") {
			setAlert(true);
		} else if (
			mesIngreso != "Selecciona..." ||
			yearIngreso != "Selecciona..."
		) {
			actions.getVentasUsuariosModificarIngresoDatos(yearIngreso, mesIngreso);
		} else {
			console.log("Weird");
		}
	};

	return (
		<div>
			<p>Selecciona Mes y/o Año</p>
			<div className='columns small-12'>
				<div className='columns small-5'>
					<label>
						Año
						<select onClick={(e) => setYearIngreso(e.target.value)}>
							<option value='Selecciona...'>Selecciona...</option>
							<option value='2022'>2022</option>
							<option value='2023'>2023</option>
							<option value='2024'>2024</option>
							<option value='2025'>2025</option>
						</select>
					</label>
				</div>
				<div className='columns small-1'>
					{" "}
					<br />
					y/o
				</div>
				<div className='columns small-6'>
					<label>
						Mes
						<select onClick={(e) => setMesIngreso(e.target.value)}>
							<option value='Selecciona...'>Selecciona...</option>
							<option value='Enero'>Enero</option>
							<option value='Febrero'>Febrero</option>
							<option value='Marzo'>Marzo</option>
							<option value='Abril'>Abril</option>
							<option value='Mayo'>Mayo</option>
							<option value='Junio'>Junio</option>
							<option value='Julio'>Julio</option>
							<option value='Agosto'>Agosto</option>
							<option value='Septiembre'>Septiembre</option>
							<option value='Octubre'>Octubre</option>
							<option value='Noviembre'>Noviembre</option>
							<option value='Diciembre'>Diciembre</option>
						</select>
					</label>
				</div>
			</div>
			<button className='button' onClick={(e) => HandlerBuscarVentasUsuario(e)}>
				Buscar
			</button>
			{alert && <div className='callout alert'>Debes Seleccionar Algo</div>}
		</div>
	);
};

export default OpcionesModificarIngresoDatos;
