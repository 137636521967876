import React, { Fragment, useState, useContext, useEffect } from "react";
import { Context } from "../store/AppContext";
import { useNavigate } from "react-router-dom";
import "../../styles/GeneradorDocumentos.css";
import cedula from "../../img/cedula.jpg";
import Head from "../component/Head";
import Opciones from "../component/GeneradorDocumentos/Opciones";
import Formulario from "../component/GeneradorDocumentos/Formulario";
import FormularioContabilidadPersonaNatural from "../component/GeneradorDocumentos/FormularioContabilidadPersonaNatural";
import FormularioDocumentosPatente from "../component/GeneradorDocumentos/FormularioDocumentosPatente";
import { PoderSII } from "../Helper/GeneradorDocumentos/PoderSII";
import { PoderMunicipal } from "../Helper/GeneradorDocumentos/PoderMunicipal";
import { ContratoMensual } from "../Helper/GeneradorDocumentos/ContratoMensual";
import { ContratoAnual } from "../Helper/GeneradorDocumentos/ContratoAnual";
import { Contrato30dias } from "../Helper/GeneradorDocumentos/Contrato30dias";
import { ContratoContabilidad } from "../Helper/GeneradorDocumentos/ContratoContabilidad";
import { ContratoContabilidadPersonaNatural } from "../Helper/GeneradorDocumentos/ContratoContabilidadPersonaNatural";
import { DocumentosPatente } from "../Helper/GeneradorDocumentos/DocumentosPatente";

const GeneradorDocumentos = () => {
	const { store, actions } = useContext(Context);
	const history = useNavigate();
	const [img, setImg] = useState(cedula);
	const [datos, setDatos] = useState({
		nombre: "",
		rut: "",
		razon: "",
		rutEmpresa: "",
		fecha: "",
		nacionalidad: "",
		planContratado: "",
	});
	const [datosDocumentosPatente, setDatosDocumentosPatente] = useState({
		nombre: "",
		rut: "",
		razon: "",
		rutEmpresa: "",
		actividadEspecifica: "",
		codigo1: "",
		giro1: "",
		codigo2: "",
		giro2: "",
		codigo3: "",
		giro3: "",
		numeroTrabajadoresInmueble: "",
		numeroTrabajadoresPais: "",
		capitalNegocio: "",
	});
	const [opcion, setOpcion] = useState("Poder SII");
	const [alert, setAlert] = useState(false);
	const [animar, setAnimar] = useState(false);

	useEffect(() => {
		setTimeout(() => {
			if (store.usuarioActual == null && store.token == null) {
				history("/");
			}
		}, 200);
	});

	//Detecta unmounted
	useEffect(() => {
		setAnimar(true);
	}, []);

	/* Al hacer click en en boton de generar, se evalua si están todos los datos completos. Si ok, procede a imprimir. */
	const HandlerClick = () => {
		let fecha = new Date();
		const opciones = {
			year: "numeric",
			month: "long",
			day: "2-digit",
		};

		let JSONObject = {
			nombreRepresentante: datos.nombre,
			rutRepresentante: datos.rut,
			razon: datos.razon,
			rutEmpresa: datos.rutEmpresa,
			fecha:
				datos.fecha === "hoy"
					? fecha.toLocaleDateString("es-ES", opciones)
					: datos.fecha,
			personaGenera: store.usuarioActual,
			tipoContrato: opcion,
		};

		if (opcion == "Documentos Patente") {
			if (
				datosDocumentosPatente.nombre.trim() === "" ||
				datosDocumentosPatente.rut.trim() === "" ||
				datosDocumentosPatente.razon.trim() === "" ||
				datosDocumentosPatente.rutEmpresa.trim() === "" ||
				datosDocumentosPatente.actividadEspecifica.trim() === "" ||
				datosDocumentosPatente.codigo1.trim() === "" ||
				datosDocumentosPatente.giro1.trim() === "" ||
				datosDocumentosPatente.numeroTrabajadoresInmueble.trim() === "" ||
				datosDocumentosPatente.numeroTrabajadoresPais.trim() === "" ||
				datosDocumentosPatente.capitalNegocio.trim() === ""
			) {
				setAlert(true);
			} else {
				setAlert(false);
				if (opcion == "Documentos Patente") {
					DocumentosPatente(datosDocumentosPatente);
				} else {
					setAlert(true);
				}
			}
		}
		if (opcion == "Contrato Contabilidad") {
			if (
				datos.nombre.trim() === "" ||
				datos.rut.trim() === "" ||
				datos.razon.trim() === "" ||
				datos.rutEmpresa.trim() === "" ||
				datos.nacionalidad.trim() === "" ||
				datos.fecha.trim() === "" ||
				datos.planContratado.trim() === ""
			) {
				setAlert(true);
			} else {
				setAlert(false);
				ContratoContabilidad(datos);
			}
		}
		if (opcion == "Conta Persona Natural") {
			if (
				datos.nombre.trim() === "" ||
				datos.rut.trim() === "" ||
				datos.nacionalidad.trim() === "" ||
				datos.fecha.trim() === "" ||
				datos.planContratado.trim() === ""
			) {
				setAlert(true);
			} else {
				setAlert(false);
				ContratoContabilidadPersonaNatural(datos);
			}
		}
		if (
			opcion == "Poder SII" ||
			opcion == "Poder Municipal" ||
			opcion == "Contrato Arriendo Mensual" ||
			opcion == "Contrato Arriendo Anual" ||
			opcion == "Contrato Arriendo 30 dias"
		) {
			if (
				datos.nombre.trim() === "" ||
				datos.rut.trim() === "" ||
				datos.razon.trim() === "" ||
				datos.rutEmpresa.trim() === "" ||
				datos.nacionalidad.trim() === "" ||
				datos.fecha.trim() === ""
			) {
				setAlert(true);
			} else {
				setAlert(false);
				if (opcion == "Poder SII" && img != cedula) {
					PoderSII(datos, img);
				} else if (opcion == "Poder Municipal" && img != cedula) {
					PoderMunicipal(datos, img);
				} else if (opcion == "Contrato Arriendo Mensual" && img != cedula) {
					ContratoMensual(datos, img);
					fetch("https://hooks.zapier.com/hooks/catch/2499772/3r1swrr/", {
						method: "POST",
						body: JSON.stringify(JSONObject),
					});
				} else if (opcion == "Contrato Arriendo Anual" && img != cedula) {
					ContratoAnual(datos, img);
					fetch("https://hooks.zapier.com/hooks/catch/2499772/3r1swrr/", {
						method: "POST",
						body: JSON.stringify(JSONObject),
					});
				} else if (opcion == "Contrato Arriendo 30 dias" && img != cedula) {
					Contrato30dias(datos, img);
					fetch("https://hooks.zapier.com/hooks/catch/2499772/3r1swrr/", {
						method: "POST",
						body: JSON.stringify(JSONObject),
					});
				} else if (opcion == "Contrato Contabilidad") {
					if (datos.planContratado.trim() === "") {
						setAlert(true);
					} else {
						ContratoContabilidad(datos);
					}
				} else if (opcion == "Conta Persona Natural") {
					if (datos.planContratado.trim() === "") {
						setAlert(true);
					} else {
						ContratoContabilidadPersonaNatural(datos);
					}
				} else {
					setAlert(true);
				}
			}
		}
	};

	const HandlerReset = () => {
		setDatos({
			nombre: "",
			rut: "",
			razon: "",
			rutEmpresa: "",
			fecha: "",
			nacionalidad: "",
			planContratado: "",
		});
		setDatosDocumentosPatente({
			nombre: "",
			rut: "",
			razon: "",
			rutEmpresa: "",
			codigo1: "",
			giro1: "",
			codigo2: "",
			giro2: "",
			codigo3: "",
			giro3: "",
			numeroTrabajadoresInmueble: "",
			numeroTrabajadoresPais: "",
			capitalNegocio: "",
		});
		setImg(cedula);
	};

	const titulosHead = [
		"Bienvenido al Generador de Documentos",
		"Elige una opción, y genera tus documentos.",
	];

	return (
		<Fragment>
			<Head contenido={titulosHead} />
			<div className={`divGenerador ${animar && "animar"}`}>
				<Opciones opcion={opcion} setOpcion={setOpcion} />
				<hr />
				{alert ? (
					<div className='callout alert text-center'>
						Todos los Datos son Necesarios
					</div>
				) : null}
				{opcion == "Documentos Patente" && (
					<FormularioDocumentosPatente
						datosDocumentosPatente={datosDocumentosPatente}
						setDatosDocumentosPatente={setDatosDocumentosPatente}
					/>
				)}
				{opcion == "Conta Persona Natural" && (
					<FormularioContabilidadPersonaNatural
						img={img}
						setImg={setImg}
						datos={datos}
						setDatos={setDatos}
						opcion={opcion}
					/>
				)}
				{opcion != "Documentos Patente" &&
					opcion != "Conta Persona Natural" && (
						<Formulario
							img={img}
							setImg={setImg}
							datos={datos}
							setDatos={setDatos}
							opcion={opcion}
						/>
					)}
				<div className='row'>
					<div className='columns small-6'>
						<button
							className='button expanded'
							onClick={(e) => HandlerClick(e)}
						>
							Generar {opcion}
						</button>
					</div>
					<div className='columns small-6'>
						<button className='alert button' onClick={(e) => HandlerReset(e)}>
							Reset
						</button>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default GeneradorDocumentos;
