import React, { Fragment, useContext, useState, useEffect } from "react";
import { Context } from "../../../../store/AppContext";
import PaginadorContabilidad from "../../PaginadorContabilidad.js";
import ListaClientesContaEspecifico from "./ListaClientesContaEspecifico";
import ClienteSeleccionado from "../../ClienteSeleccionado.js";
import BuscadorEspecifico from "./BuscadorEspecifico";
import { ExportTableToExcel } from "../../../../Helper/ExportTableToExcel";

const VistaBuscadorEspecificoConta = ({ setBusquedaClientes }) => {
	const { store, actions } = useContext(Context);
	const [clienteSeleccionado, setClienteSeleccionado] = useState(false);
	const [buscando, setBuscando] = useState(false); //Detecta si hemos apretado el botón Buscar o hemos dato Enter
	const [clienteContaBuscado, setClienteContaBuscado] = useState(null); // Actualiza lo Buscado
	const [clienteContaCliqueado, setClienteContaCliqueado] = useState(null);
	const [colores, setColores] = useState(false); //Controla la Aplicación de Colores en las tablas.
	const [animar, setAnimar] = useState(false);

	useEffect(() => {
		if (localStorage.getItem("colorFiltroDt") != null) {
			if (localStorage.getItem("colorFiltroDt") === "true") {
				setColores(true);
			} else {
				setColores(false);
			}
		}
	}, []);

	//Animacion
	useEffect(() => {
		setAnimar(true);
	}, []);

	const HandlerCerrar = (event) => {
		setAnimar(false);
		setTimeout(() => {
			setBusquedaClientes(false);
		}, 500);
	};

	//Las siguientes funciones son las que permiten algunas acciones básicas en la página.

	const HandlerExportarTabla = (event) => {
		ExportTableToExcel("xlsx");
	};

	const HandlerBuscar = (event) => {
		actions.getBusquedaContabilidad(clienteContaBuscado);
		buscando ? setBuscando(true) : setBuscando(true);
	};

	const HandlerSwitchColores = (event) => {
		if (colores === false) {
			setColores(true);
			localStorage.setItem("colorFiltroDt", "true");
		} else if (colores === true) {
			setColores(false);
			localStorage.setItem("colorFiltroDt", "false");
		}
	};

	return (
		<div className={`divGenerador ${animar ? "animar" : "noAnimar"}`}>
			<div className='row button-group align-right'>
				<button className='button secondary' onClick={(e) => HandlerCerrar(e)}>
					Regresar
				</button>
			</div>
			<div className='row'>
				{clienteSeleccionado && (
					<ClienteSeleccionado
						setClienteSeleccionado={setClienteSeleccionado}
						clienteContaCliqueado={clienteContaCliqueado}
					/>
				)}
				{!clienteSeleccionado && (
					<Fragment>
						<div className='row'>
							<div
								className='grid-x grid-margin-x'
								style={{
									boxShadow: "0px 4px 8px #000000",
									paddingTop: "20px",
									paddingBottom: "5px",
								}}
							>
								<BuscadorEspecifico
									clienteContaBuscado={clienteContaBuscado}
									setClienteContaBuscado={setClienteContaBuscado}
									buscando={buscando}
									setBuscando={setBuscando}
								/>
								<div className='cell small-1 text-right'>
									<a
										className='clear button secondary'
										onClick={(e) => HandlerBuscar(e)}
									>
										Buscar
									</a>
								</div>
							</div>
						</div>
						<br />
						<hr />
						{buscando && (
							<>
								{/* Botones de Opciones */}
								<div className='row'>
									<div className='columns small-12'>
										<div className='columns small-9'>&nbsp;</div>
										<div className='columns small-1'>
											{/* Este es el Switch para la Aplicación de Colores */}
											<div>
												<div className='switch large'>
													<input
														className='switch-input'
														id='yes-noEspecifico'
														type='checkbox'
														name='exampleSwitch'
														checked={false}
													/>
													<label
														className='switch-paddle'
														htmlFor='yes-noEspecifico'
														disabled
													>
														<span className='show-for-sr'>Colores?</span>
														<span className='switch-active' aria-hidden='true'>
															Si
														</span>
														<span
															className='switch-inactive'
															aria-hidden='true'
														>
															No
														</span>
													</label>
												</div>
											</div>
										</div>
										<div className='columns small-2'>
											<button
												className='submit button expanded'
												onClick={(e) => HandlerExportarTabla(e)}
											>
												Exportar Seleccion
											</button>
										</div>
									</div>
								</div>
								<br />
								<ListaClientesContaEspecifico
									clienteContaBuscado={clienteContaBuscado}
									setClienteSeleccionado={setClienteSeleccionado}
									setClienteContaCliqueado={setClienteContaCliqueado}
									colores={colores}
								/>
								<div className='' style={{ textAlign: "center" }}>
									<br />
									<PaginadorContabilidad />
								</div>
							</>
						)}
					</Fragment>
				)}
			</div>
		</div>
	);
};

export default VistaBuscadorEspecificoConta;
