const BotonesOpciones = ({
	HandlerNuevoCliente,
	animar,
	HandlerBusquedaClientes,
	HandlerPantallaAntigua,
	HandlerBusquedaCompleta,
}) => {
	return (
		<div className={`row divGenerador ${animar && "animar"}`}>
			<div className='columns small-12 callout'>
				<div className='columns small-4'>Puedes:</div>
				<div className='columns small-8'>Ver Listado de Clientes:</div>
				&nbsp;
				<div className='columns small-12'>
					<div className='columns small-4'>
						<a
							className='success button large'
							onClick={(e) => HandlerNuevoCliente(e)}
						>
							Agregar Cliente Nuevo
						</a>
					</div>
					<div className='columns small-2'>
						<a
							className='button large'
							id='Vigente'
							onClick={(e) => HandlerBusquedaCompleta(e)}
						>
							Vigentes
						</a>
					</div>
					<div className='columns small-2'>
						<a
							className='button large'
							id='No Vigente'
							onClick={(e) => HandlerBusquedaCompleta(e)}
						>
							No Vigentes
						</a>
					</div>
					<div className='columns small-2'>
						<a
							className='button large'
							id='Con Deuda'
							onClick={(e) => HandlerBusquedaCompleta(e)}
						>
							Con Deuda
						</a>
					</div>
					<div className='columns small-2'>
						<a
							className='button large'
							id='Sin Deuda'
							onClick={(e) => HandlerBusquedaCompleta(e)}
						>
							Sin Deuda
						</a>
					</div>
					<div className='columns small-4'>&nbsp;</div>
					<div className='columns small-2'>
						<a
							className='button large'
							id='Con Patente'
							onClick={(e) => HandlerBusquedaCompleta(e)}
						>
							Con Patente
						</a>
					</div>
					<div className='columns small-2'>
						<a
							className='button large'
							id='Sin Patente'
							onClick={(e) => HandlerBusquedaCompleta(e)}
						>
							Sin Patente
						</a>
					</div>
					<div className='columns small-2'>
						<a
							className='button large'
							id='Con DICOM'
							onClick={(e) => HandlerBusquedaCompleta(e)}
						>
							Con DICOM
						</a>
					</div>
					<div className='columns small-2'>
						<a
							className='button large'
							id='Sin DICOM'
							onClick={(e) => HandlerBusquedaCompleta(e)}
						>
							Sin DICOM
						</a>
					</div>
					<div className='columns small-4'>&nbsp;</div>
					<div className='columns small-4'>
						<a
							className='button large'
							id='Contrato Actualizado'
							onClick={(e) => HandlerBusquedaCompleta(e)}
						>
							Contrato Actualizado
						</a>
					</div>
					<div className='columns small-4'>
						<a
							className='button large'
							id='Contrato No Actualizado'
							onClick={(e) => HandlerBusquedaCompleta(e)}
						>
							Contrato No Actualizado
						</a>
					</div>
				</div>
			</div>
			&nbsp;
			<div className='columns small-12 callout'>
				<div className='columns small-12'>También Puedes:</div>
				&nbsp;
				<div className='columns small-12'>
					<a
						className='warning button large expanded'
						onClick={(e) => HandlerBusquedaClientes(e)}
					>
						Realizar Busqueda de Clientes
					</a>
				</div>
			</div>
			&nbsp;
			<div className='columns small-12 callout'>
				<div className='columns small-12'>Opciones Antiguas:</div>
				&nbsp;
				<div className='columns small-12'>
					<div className='columns small-3'>
						<a
							className='button alert large disabled'
							onClick={(e) => HandlerPantallaAntigua(e)}
						>
							Pantalla Antigua
						</a>
					</div>
					&nbsp;
					<div className='columns small-4'>
						<a
							className='button alert large'
							id='Todo'
							onClick={(e) => HandlerBusquedaCompleta(e)}
						>
							Mostrar Todos los Clientes
						</a>
					</div>
					<div className='columns small-4'>&nbsp;</div>
				</div>
			</div>
		</div>
	);
};

export default BotonesOpciones;
