import { useContext } from "react";
import { Context } from "../../../store/AppContext";

const OpcionesEstadisticasVendedores = ({
	mes,
	setMes,
	year,
	setYear,
	setAlert,
	setEstadisticasDetalle,
}) => {
	const { store, actions } = useContext(Context);

	const HandlerMes = (e) => {
		setMes(e.target.value);
	};

	const HandlerYear = (e) => {
		setYear(e.target.value);
	};

	const HandlerBuscarEstadisticas = () => {
		if (mes === null || year === null) {
			setAlert(true);
		}

		if (mes != null && year != null) {
			setAlert(false);
			actions.setSpinner();
			actions.getEstadisticasVentas(year, mes);
			setTimeout(() => {
				actions.setSpinner();
				setEstadisticasDetalle(true);
			}, 1000);
		}
	};

	return (
		<div>
			<p>Selecciona Mes y Año para Desplegar Estadisticas</p>
			<div className='columns small-12'>
				<div className='columns small-5'>
					<label>
						Año
						<select
							onChange={(e) => HandlerYear(e)}
							defaultValue={year.toString()}
						>
							<option value='Selecciona...'>Selecciona...</option>
							<option value='2023'>2023</option>
							<option value='2024'>2024</option>
							<option value='2025'>2025</option>
							<option value='2026'>2026</option>
							<option value='2027'>2027</option>
						</select>
					</label>
				</div>
				<div className='columns small-1'>
					{" "}
					<br />
					&nbsp; &nbsp; y
				</div>
				<div className='columns small-6'>
					<label>
						Mes
						<select
							onChange={(e) => HandlerMes(e)}
							defaultValue={mes.toString()}
						>
							<option value='Selecciona...'>Selecciona...</option>
							<option value='Enero'>Enero</option>
							<option value='Febrero'>Febrero</option>
							<option value='Marzo'>Marzo</option>
							<option value='Abril'>Abril</option>
							<option value='Mayo'>Mayo</option>
							<option value='Junio'>Junio</option>
							<option value='Julio'>Julio</option>
							<option value='Agosto'>Agosto</option>
							<option value='Septiembre'>Septiembre</option>
							<option value='Octubre'>Octubre</option>
							<option value='Noviembre'>Noviembre</option>
							<option value='Diciembre'>Diciembre</option>
						</select>
					</label>
				</div>
			</div>
			<button className='button' onClick={(e) => HandlerBuscarEstadisticas(e)}>
				Buscar
			</button>
		</div>
	);
};

export default OpcionesEstadisticasVendedores;
