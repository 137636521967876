import React from "react";

const Head = ({ contenido }) => {
	const [titulo, subtitulo = ""] = contenido;
	const d = new Date();
	let month = d.getMonth();

	const mesNormal = () => {
		return (
			<div
				className='callout primary no-print'
				style={{ backgroundColor: "#2c3840", color: "#e6e6e6" }}
			>
				<div className='row column inline'>
					<h4>{titulo}</h4>
					<p>{subtitulo}</p>
				</div>
			</div>
		);
	};

	const mesFiestasPatrias = () => {
		return (
			<div
				className='callout primary no-print'
				style={{ backgroundColor: "#2c3840", color: "#e6e6e6" }}
			>
				<div className='mesFiestasPatrias'>&nbsp;</div>
				<div className='row column inline'>
					<h4>{titulo} 🇨🇱</h4>
					<p>{subtitulo}</p>
				</div>
			</div>
		);
	};

	const mesNavidad = () => {
		return (
			<div
				className='callout primary no-print'
				style={{
					backgroundColor: "#2c3840",
					color: "#e6e6e6",
					paddingTop: "0px",
				}}
			>
				<div className='mesNavidad'>
					&nbsp;
					<br />
					<br />
					<br />
					&nbsp;
				</div>
				<div className='row column inline'>
					<h4>{titulo} 🎅</h4>
					<p>{subtitulo}</p>
				</div>
			</div>
		);
	};

	return (
		<>
			{month != 8 && month != 11 && <>{mesNormal()}</>}
			{month === 8 && <>{mesFiestasPatrias()}</>}
			{month === 11 && <>{mesNavidad()}</>}
		</>
	);
};

export default Head;
