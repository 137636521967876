import React, { useState, useContext, useEffect } from "react";
import { Context } from "../../../../store/AppContext";
import { AiOutlineWarning } from "react-icons/ai";

//Este Formulario es de Direccion Tributaria (Modificar Cliente Existente)

const BorrarPago = ({
	pagoSeleccionado,
	setPagoSeleccionado,
	setDSetectorCambios,
	setDisabled,
}) => {
	const { store, actions } = useContext(Context);

	useEffect(() => {
		actions.getClienteDt(store.infoClienteDt.id);
	}, []);

	const HandlerCerrar = (event) => {
		setDisabled(false);
		setPagoSeleccionado({
			object: null,
			year: null,
		});
	};

	const HandlerBorrarPago = (event) => {
		if (
			pagoSeleccionado.year.toString() === "2023" ||
			pagoSeleccionado.year.toString() === "2024" ||
			pagoSeleccionado.year.toString() === "2025" ||
			pagoSeleccionado.year.toString() === "2026" ||
			pagoSeleccionado.year.toString() === "2027"
		) {
			actions.editarMesesPagados2023ClienteDt(
				store.infoClienteDt.id,
				parseInt(store.infoClienteDt.mesesPagados2023) -
					parseInt(pagoSeleccionado.object.mesesPagados2023)
			);
			actions.borrarPago(pagoSeleccionado.year, pagoSeleccionado.object.id);
			setTimeout(() => {
				setPagoSeleccionado({
					object: null,
					year: null,
				});
			}, 200);
			setDSetectorCambios(true);
			setDisabled(false);
		}

		if (
			pagoSeleccionado.year.toString() === "2022" ||
			pagoSeleccionado.year.toString() === "2021" ||
			pagoSeleccionado.year.toString() === "2020" ||
			pagoSeleccionado.year.toString() === "2019"
		) {
			actions.editarMesesPagadosClienteDt(
				store.infoClienteDt.id,
				parseInt(store.infoClienteDt.mesesPagados) -
					parseInt(pagoSeleccionado.object.mesesPagados)
			);
			actions.borrarPago(pagoSeleccionado.year, pagoSeleccionado.object.id);
			setTimeout(() => {
				setPagoSeleccionado({
					object: null,
					year: null,
				});
			}, 200);
			setDSetectorCambios(true);
			setDisabled(false);
		}
	};

	return (
		<div className='DivTerciario text-center' style={{ background: "red" }}>
			<div>
				<h4>¿Estás Seguro que deseas borrar este Pago?</h4>
				<p>
					Vas a borrar un pago del mes de{" "}
					<strong>
						{pagoSeleccionado.object.mes} {pagoSeleccionado.year}
					</strong>
					, por un monto pagado de{" "}
					<strong>${pagoSeleccionado.object.montoPagado}</strong>.
				</p>
			</div>
			<div className='button-group align-center'>
				<button
					className='submit button alert'
					onClick={(e) => HandlerBorrarPago(e)}
				>
					Borrar Pago para Siempre
				</button>
				<button className='submit button' onClick={(e) => HandlerCerrar(e)}>
					Cancelar
				</button>
			</div>
			<div>
				<h3>
					<AiOutlineWarning /> Vas a borrar un pago, este cambio es
					irrecuperable.
				</h3>
			</div>
		</div>
	);
};

export default BorrarPago;
