import React, { useState, useContext } from "react";
import { Context } from "../../store/AppContext";
import {
	AiFillEdit,
	AiOutlineSmallDash,
	AiFillMinusCircle,
} from "react-icons/ai";

export const ListaUsuarios = ({
	setModificar,
	usuarioSeleccionado,
	setUsuarioCliqueado,
	setEliminar,
	usuarioSeleccionadoEliminar,
}) => {
	const { store, actions } = useContext(Context);

	const Clientes = () => {
		return (
			<table className={`table hover ${store.witch ? "tablaUsuarios" : ""}`}>
				<thead>
					<tr>
						<th scope='col'>Id</th>
						<th scope='col'>Nombre</th>
						<th scope='col'>Apellido</th>
						<th scope='col'>Correo</th>
						<th scope='col'>Tipo de Usuario</th>
						<th scope='col'></th>
					</tr>
				</thead>
				<tbody>
					{store.usuarios != null
						? store.usuarios.map((objeto, i) =>
								ListaDesplegarUsuarios(objeto, i)
						  )
						: null}
				</tbody>
			</table>
		);
	};

	const HandlerClick = (object) => {
		if (object.tipo == "Super Administrador") {
			alert("No puedes modificar/eliminar un Super Administrador");
		} else if (usuarioSeleccionado) {
			actions.getUsuario(object.id);
			setUsuarioCliqueado(object);
			setModificar(true);
		} else if (usuarioSeleccionadoEliminar) {
			actions.getUsuario(object.id);
			setUsuarioCliqueado(object);
			setEliminar(true);
		}
	};

	const ListaDesplegarUsuarios = (objeto, i) => {
		return (
			<tr key={i}>
				<td>{i + 1}</td>
				<td>{objeto.nombre}</td>
				<td>{objeto.apellido}</td>
				<td>{objeto.correo}</td>
				<td>{objeto.tipo}</td>
				{!usuarioSeleccionado && !usuarioSeleccionadoEliminar ? (
					<td>
						<a className='clear button' disabled>
							<AiOutlineSmallDash />
						</a>
					</td>
				) : null}
				{usuarioSeleccionado ? (
					<td className={usuarioSeleccionado ? "trCliqueable" : null}>
						<a className='clear button' onClick={() => HandlerClick(objeto)}>
							<AiFillEdit />
						</a>
					</td>
				) : null}
				{usuarioSeleccionadoEliminar ? (
					<td className={usuarioSeleccionadoEliminar ? "trCliqueable" : null}>
						<a className='clear button' onClick={() => HandlerClick(objeto)}>
							<AiFillMinusCircle />
						</a>
					</td>
				) : null}
			</tr>
		);
	};

	return <div className=''>{store.usuarios != null ? <Clientes /> : null}</div>;
};

export default ListaUsuarios;
