const OpcionesNuevo = ({ undo, redo, addRow, lessRow, guardar, mes0 }) => {
	return (
		<div className='columns small-12'>
			<div className='columns small-3'>
				<button
					title='Deshacer'
					type='button'
					onClick={undo}
					className='button'
				>
					&#8617;
				</button>
				&nbsp;
				<button title='Rehacer' type='button' onClick={redo} className='button'>
					&#8618;
				</button>
				&nbsp;
				<button
					title='Más Filas'
					type='button'
					onClick={addRow}
					className='button'
				>
					&#x271A;
				</button>
				&nbsp;
				<button
					title='Menos Filas'
					type='button'
					onClick={lessRow}
					className='button'
				>
					&#8722;
				</button>
				&nbsp;
				<button
					title='Guardar'
					type='button'
					onClick={guardar}
					className='button'
				>
					🖫
				</button>
			</div>
			<div className='columns small-9'>
				{mes0 && (
					<button className='clear button alert'>
						Los cambios no han sido guardados aún.
					</button>
				)}
			</div>
		</div>
	);
};

export default OpcionesNuevo;
