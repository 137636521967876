import React, { useContext } from "react";
import { Context } from "../../store/AppContext";
import "./botonOtrosMeses.css";

const BotonOtrosMeses = ({ mes, setMes }) => {
	const { store, actions } = useContext(Context);

	const ListaDesplegarMeses = (objeto, i) => {
		return (
			<a key={i} href='#' onClick={(e) => setMes(i)}>
				{`${objeto.mesIngreso} ${objeto.yearIngreso}`}
			</a>
		);
	};

	return (
		<div className='dropdownVentas'>
			<button
				type='button'
				className='button dropbtnVentas'
				style={{ marginTop: "1px" }}
			>
				&#x2752;
			</button>
			<div className='dropdown-contentVentas'>
				{store.ventasUsuarioVendedores != null ? (
					store.ventasUsuarioVendedores.map((objeto, i) =>
						ListaDesplegarMeses(objeto, i)
					)
				) : (
					<td colSpan='9'>
						<h3 className='text-center'> - No hay meses -</h3>
					</td>
				)}
			</div>
		</div>
	);
};

export default BotonOtrosMeses;
