const DiasHabiles = (startDate, endDate) => {
	var startDate =
		typeof startDate == "object" ? startDate : new Date(startDate);
	var endDate = typeof endDate == "object" ? endDate : new Date(endDate);
	if (endDate > startDate) {
		var days = Math.ceil(
			(endDate.setHours(23, 59, 59, 999) - startDate.setHours(0, 0, 0, 1)) /
				(86400 * 1000)
		);
		var weeks = Math.floor(
			Math.ceil(
				(endDate.setHours(23, 59, 59, 999) - startDate.setHours(0, 0, 0, 1)) /
					(86400 * 1000)
			) / 7
		);

		days = days - weeks * 2;
		days = startDate.getDay() - endDate.getDay() > 1 ? days - 2 : days;
		days = startDate.getDay() == 0 && endDate.getDay() != 6 ? days - 1 : days;
		days = endDate.getDay() == 6 && startDate.getDay() != 0 ? days - 1 : days;

		return days;
	}
	return null;
};

export default DiasHabiles;
