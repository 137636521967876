import React, { Fragment, useContext, useEffect, useState } from "react";
import Head from "../component/Head";
import { Context } from "../store/AppContext";
import { useNavigate } from "react-router-dom";
import "../../styles/Administracion.css";
import CreacionUsuario from "../component/Administracion/CreacionUsuario";
import ListaUsuarios from "../component/Administracion/ListaUsuarios";
import ModificarUsuario from "../component/Administracion/ModificarUsuario";
import EliminarUsuario from "../component/Administracion/EliminarUsuario";
import "../../styles/Modal.css";

const Administracion = () => {
	const { store, actions } = useContext(Context);
	const history = useNavigate();
	const [crear, setCrear] = useState(false);
	const [modificar, setModificar] = useState(false);
	const [eliminar, setEliminar] = useState(false);
	const [usuarioSeleccionado, setUsuarioSeleccionado] = useState(false); //Boton Modificar Permite Cliquear al ser true
	const [usuarioSeleccionadoEliminar, setUsuarioSeleccionadoEliminar] =
		useState(false);
	const [usuarioCliqueado, setUsuarioCliqueado] = useState(null);
	const titulosHead = [
		"Bienvenido a Administracion DeNegocios.cl",
		"Aquí puedes Crear, Borrar o Editar un Usuario.",
	];
	const [animarModal, setAnimarModal] = useState(false);

	useEffect(() => {
		actions.getUsuarios();
	}, []);

	useEffect(() => {
		setTimeout(() => {
			if (store.usuarioActual == null && store.token == null) {
				history("/");
			}
		}, 200);
	});

	useEffect(() => {
		if (
			store.usuarioActual != null &&
			(store.usuarioActual.tipo == "Cobranza" ||
				store.usuarioActual.tipo == "Vendedor" ||
				store.usuarioActual.tipo == "Soporte" ||
				store.usuarioActual.tipo == "Contabilidad")
		) {
			history("/");
		}
	});

	const HandlerCrear = (event) => {
		setCrear(true);
		setTimeout(() => {
			setAnimarModal(true);
		}, 500);
	};

	const HandlerModificar = (event) => {
		usuarioSeleccionado
			? setUsuarioSeleccionado(false)
			: setUsuarioSeleccionado(true);
		setTimeout(() => {
			setAnimarModal(true);
		}, 500);
	};

	const HandlerEliminar = (event) => {
		usuarioSeleccionadoEliminar
			? setUsuarioSeleccionadoEliminar(false)
			: setUsuarioSeleccionadoEliminar(true);
		setTimeout(() => {
			setAnimarModal(true);
		}, 500);
	};

	return (
		<Fragment>
			<Head contenido={titulosHead} />
			<div className='row'>
				<div className='column'>
					{!crear && !modificar ? (
						<div className=''>
							<div className='button-group align-right'>
								<a
									className='button alert'
									name='Eliminar'
									disabled={crear || usuarioSeleccionado ? true : false}
									onClick={(e) => HandlerEliminar(e)}
								>
									Eliminar
								</a>
								<a
									className='button warning'
									name='Modificar'
									disabled={crear || usuarioSeleccionadoEliminar ? true : false}
									onClick={(e) => HandlerModificar(e)}
								>
									Modificar
								</a>
								<a
									className='button primary'
									name='Crear'
									disabled={
										usuarioSeleccionado || usuarioSeleccionadoEliminar
											? true
											: false
									}
									onClick={(e) => HandlerCrear(e)}
								>
									Crear Usuario
								</a>
							</div>
						</div>
					) : null}
					<ListaUsuarios
						setModificar={setModificar}
						usuarioSeleccionado={usuarioSeleccionado}
						setUsuarioCliqueado={setUsuarioCliqueado}
						setEliminar={setEliminar}
						usuarioSeleccionadoEliminar={usuarioSeleccionadoEliminar}
					/>
					{crear ? (
						<CreacionUsuario
							setCrear={setCrear}
							setAnimarModal={setAnimarModal}
							animarModal={animarModal}
						/>
					) : null}
					{modificar ? (
						<ModificarUsuario
							setModificar={setModificar}
							usuarioCliqueado={usuarioCliqueado}
							setUsuarioCliqueado={setUsuarioCliqueado}
							setAnimarModal={setAnimarModal}
							animarModal={animarModal}
						/>
					) : null}
					{eliminar ? (
						<EliminarUsuario
							setEliminar={setEliminar}
							usuarioCliqueado={usuarioCliqueado}
							setUsuarioCliqueado={setUsuarioCliqueado}
							setAnimarModal={setAnimarModal}
							animarModal={animarModal}
						/>
					) : null}
				</div>
			</div>
		</Fragment>
	);
};

export default Administracion;
