import React, { Fragment, useContext, useEffect } from "react";
import { Context } from "../store/AppContext";
import { useNavigate } from "react-router-dom";
import VistaInicialDtCobranza from "../component/DireccionTributaria/UsuarioCobranza/VistaInicialDtCobranza";
import VistaDtUsuarioNormal from "../component/DireccionTributaria/UsuarioNormal/VistaDtUsuarioNormal";
import "../../styles/PagosDt.css";

const DireccionTributaria = () => {
	const { store, actions } = useContext(Context);
	const history = useNavigate();

	useEffect(() => {
		setTimeout(() => {
			if (store.usuarioActual == null && store.token == null) {
				history("/");
			}
		}, 200);
	});

	return (
		<Fragment>
			{store.usuarioActual != null ? (
				store.usuarioActual.tipo === "Administrador" ||
				store.usuarioActual.tipo === "Cobranza" ||
				store.usuarioActual.tipo === "Super Administrador" ? (
					<VistaInicialDtCobranza />
				) : (
					<VistaDtUsuarioNormal />
				)
			) : null}
		</Fragment>
	);
};

export default DireccionTributaria;
