import React, { Fragment, useState, useEffect, useContext } from "react";

const VistaEstadisticas = ({ setEstadisticas }) => {
	const HandlerCerrar = (event) => {
		setEstadisticas(false);
	};

	return (
		<Fragment>
			<div className='callout' data-closable>
				<button
					className='close-button no-print'
					aria-label='Dismiss alert'
					type='button'
					data-close
					onClick={(e) => HandlerCerrar(e)}
				>
					<span aria-hidden='true'>×</span>
				</button>
				<h1>Hola</h1>
			</div>
		</Fragment>
	);
};

export default VistaEstadisticas;
