import { useContext, useEffect, useState } from "react";
import { Context } from "../../store/AppContext";
import OpcionesEstadisticasVendedores from "./EstadisticasVendedores/OpcionesEstadisticasVendedores";
import DetalleEstadisticas from "./EstadisticasVendedores/DetalleEstadisticas";

const EstadisticasVendedores = ({ estadisticas, setEstadisticas }) => {
	const d = new Date();
	let yeard = d.getFullYear();
	const meses = [
		"Enero",
		"Febrero",
		"Marzo",
		"Abril",
		"Mayo",
		"Junio",
		"Julio",
		"Agosto",
		"Septiembre",
		"Octubre",
		"Noviembre",
		"Diciembre",
	];
	let mesd = meses[d.getMonth()];

	const { store, actions } = useContext(Context);
	const [mes, setMes] = useState(mesd);
	const [year, setYear] = useState(yeard);
	const [alert, setAlert] = useState(false);
	const [estadisticasDetalle, setEstadisticasDetalle] = useState(false);

	const HandlerCerrar = () => {
		setEstadisticas(false);
	};

	return (
		<>
			{!estadisticasDetalle && (
				<div className='row'>
					<div className='callout' data-closable>
						<button
							className='close-button'
							aria-label='Dismiss alert'
							type='button'
							data-close
							onClick={(e) => HandlerCerrar(e)}
						>
							<span aria-hidden='true'>×</span>
						</button>
						<br />
						<div className='grid-x'>
							<div className='cell small-6'>
								<h3>Estadisticas de Vendedores</h3>
							</div>
						</div>
						<>
							<OpcionesEstadisticasVendedores
								mes={mes}
								setMes={setMes}
								year={year}
								setYear={setYear}
								setAlert={setAlert}
								setEstadisticasDetalle={setEstadisticasDetalle}
							/>
							<br />
							{alert && (
								<>
									<div className='callout alert'>Debes Elegir Mes y Año</div>
									<br />
								</>
							)}
						</>
					</div>
				</div>
			)}
			{estadisticasDetalle && (
				<DetalleEstadisticas
					estadisticasDetalle={estadisticasDetalle}
					setEstadisticasDetalle={setEstadisticasDetalle}
					mes={mes}
					year={year}
				/>
			)}
		</>
	);
};

export default EstadisticasVendedores;
