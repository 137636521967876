import React, { Fragment, useContext, useState, useEffect } from "react";
import "../../styles/TopScroll.css";

const TopScroll = () => {
	const [showTopBtn, setShowTopBtn] = useState(false);
	useEffect(() => {
		window.addEventListener("scroll", () => {
			if (window.scrollY > 400) {
				setShowTopBtn(true);
			} else {
				setShowTopBtn(false);
			}
		});
	}, []);

	const TopScrollFunction = (e) => {
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		});
	};

	return (
		<>
			{showTopBtn && (
				<>
					<button
						onClick={(e) => TopScrollFunction(e)}
						className='topScrollBoton'
						title='Subir al TOP'
					>
						Subir
					</button>
				</>
			)}
		</>
	);
};

export default TopScroll;
