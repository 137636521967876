import React, { useState, useContext } from "react";
import { Context } from "../../../store/AppContext";
import { SaldoTotal } from "../../../Helper/SaldoTotal";

const InformacionCliente = () => {
	const { store, actions } = useContext(Context);

	const CalculoSaldo = (variable) => {
		if (variable) {
			if (variable === "SaldoDeberia") {
				//Este saca la cantidad de meses y su valor, desde la fecha de Contratación. Es el DEBERIA
				const fecha = new Date(store.infoClienteDt.fechaContratacion);
				const fechaHoy = new Date("12 31, 2022 23:59:59");
				let diferenciaMeses =
					fechaHoy.getMonth() -
					fecha.getMonth() +
					12 * (fechaHoy.getFullYear() - fecha.getFullYear());
				if (diferenciaMeses >= 0) {
					return diferenciaMeses.toString();
				} else {
					return 0;
				}
			}
			if (variable === "SaldoDeberia2023") {
				//Este saca la cantidad de meses y su valor, desde la fecha de Contratación. Es el DEBERIA
				const fecha = new Date("12 01, 2022 00:00:01");
				const fechaHoy = new Date();
				let diferenciaMeses =
					fechaHoy.getMonth() -
					fecha.getMonth() +
					12 * (fechaHoy.getFullYear() - fecha.getFullYear());
				return diferenciaMeses.toString();
			} else {
				console.log("Raro");
			}
		}

		if (store.infoClienteDt.vigente == "No") {
			if (store.infoClienteDt.sacar == "Si") {
				return 0;
			}
		}

		//Esto da la cantidad de meses antes del reajuste de 2023, con el precio original de 9900
		const SaldoDeberia = (meses = false) => {
			//Este saca la cantidad de meses y su valor, desde la fecha de Contratación. Es el DEBERIA
			const fecha = new Date(store.infoClienteDt.fechaContratacion);
			const fechaHoy = new Date("12 31, 2022 23:59:59");
			let diferenciaMeses =
				fechaHoy.getMonth() -
				fecha.getMonth() +
				12 * (fechaHoy.getFullYear() - fecha.getFullYear());
			/* if (fechaHoy.getDate() < fecha.getDate()) {
				diferenciaMeses = diferenciaMeses - 1;
			} */
			if (meses == true) {
				return (diferenciaMeses * 9900).toString();
			}
			if (diferenciaMeses >= 0) {
				return diferenciaMeses.toString();
			} else {
				return 0;
			}
		};

		const SaldoTotal = (meses) => {
			//Este recorre los años y saca los montos Pagados. Es el REAL.
			if (store.infoClienteDt.vigente === "Si") {
				if (
					store.pago2019 != null &&
					store.pago2020 != null &&
					store.pago2021 != null &&
					store.pago2022 != null &&
					store.pago2023 != null &&
					store.pago2024 != null &&
					store.pago2025 != null &&
					store.pago2026 != null &&
					store.pago2027 != null &&
					meses == true
				) {
					let m2019 = store.pago2019.reduce((currentSum, number) => {
						return (currentSum += parseInt(number.montoPagado));
					}, 0);

					let m2020 = store.pago2020.reduce((currentSum, number) => {
						return (currentSum += parseInt(number.montoPagado));
					}, 0);

					let m2021 = store.pago2021.reduce((currentSum, number) => {
						return (currentSum += parseInt(number.montoPagado));
					}, 0);

					let m2022 = store.pago2022.reduce((currentSum, number) => {
						return (currentSum += parseInt(number.montoPagado));
					}, 0);

					let m2023 = store.pago2023.reduce((currentSum, number) => {
						return (currentSum += parseInt(number.montoPagado));
					}, 0);

					let m2024 = store.pago2024.reduce((currentSum, number) => {
						return (currentSum += parseInt(number.montoPagado));
					}, 0);

					let m2025 = store.pago2025.reduce((currentSum, number) => {
						return (currentSum += parseInt(number.montoPagado));
					}, 0);

					let m2026 = store.pago2026.reduce((currentSum, number) => {
						return (currentSum += parseInt(number.montoPagado));
					}, 0);

					let m2027 = store.pago2027.reduce((currentSum, number) => {
						return (currentSum += parseInt(number.montoPagado));
					}, 0);

					return (
						m2019 +
						m2020 +
						m2021 +
						m2022 +
						m2023 +
						m2024 +
						m2025 +
						m2026 +
						m2027
					);
				}

				if (meses == false) {
					/* console.log("meses Pagados if"); */
					if (store.infoClienteDt.mesesPagados != null) {
						let mesesPagados = parseInt(store.infoClienteDt.mesesPagados);
						return mesesPagados;
					}
				}
			} else if (store.infoClienteDt.vigente === "No") {
				if (
					store.pago2019 != null &&
					store.pago2020 != null &&
					store.pago2021 != null &&
					store.pago2022 != null &&
					store.pago2023 != null &&
					store.pago2024 != null &&
					store.pago2025 != null &&
					store.pago2026 != null &&
					store.pago2027 != null &&
					meses == true
				) {
					let cero = 0;
					return cero;
				}

				if (meses == false) {
					if (store.infoClienteDt.mesesPagados != null) {
						let mesesPagados = parseInt(store.infoClienteDt.mesesPagados);
						return mesesPagados;
					}
				}
			}
		};

		//Desde 2023 hubo re ajuste de precios, por lo que se re ingresa formulas para deberia y real
		const SaldoDeberia2023 = (meses = false) => {
			//Este saca la cantidad de meses y su valor, desde la fecha de Contratación. Es el DEBERIA
			const fecha = new Date("12 01, 2022 00:00:01");
			const fechaHoy = new Date();
			let diferenciaMeses =
				fechaHoy.getMonth() -
				fecha.getMonth() +
				12 * (fechaHoy.getFullYear() - fecha.getFullYear());
			/* if (fechaHoy.getDate() < fecha.getDate()) {
				diferenciaMeses = diferenciaMeses - 1;
			} */
			if (meses == true) {
				return (diferenciaMeses * 9900).toString();
			}
			return diferenciaMeses.toString();
		};

		const SaldoTotal2023 = (meses) => {
			//Este recorre los años y saca los montos Pagados. Es el REAL.
			if (store.infoClienteDt.vigente === "Si") {
				if (
					store.pago2019 != null &&
					store.pago2020 != null &&
					store.pago2021 != null &&
					store.pago2022 != null &&
					store.pago2023 != null &&
					store.pago2024 != null &&
					store.pago2025 != null &&
					store.pago2026 != null &&
					store.pago2027 != null &&
					meses == true
				) {
					let m2019 = store.pago2019.reduce((currentSum, number) => {
						return (currentSum += parseInt(number.montoPagado));
					}, 0);

					let m2020 = store.pago2020.reduce((currentSum, number) => {
						return (currentSum += parseInt(number.montoPagado));
					}, 0);

					let m2021 = store.pago2021.reduce((currentSum, number) => {
						return (currentSum += parseInt(number.montoPagado));
					}, 0);

					let m2022 = store.pago2022.reduce((currentSum, number) => {
						return (currentSum += parseInt(number.montoPagado));
					}, 0);

					let m2023 = store.pago2023.reduce((currentSum, number) => {
						return (currentSum += parseInt(number.montoPagado));
					}, 0);

					let m2024 = store.pago2024.reduce((currentSum, number) => {
						return (currentSum += parseInt(number.montoPagado));
					}, 0);

					let m2025 = store.pago2025.reduce((currentSum, number) => {
						return (currentSum += parseInt(number.montoPagado));
					}, 0);

					let m2026 = store.pago2026.reduce((currentSum, number) => {
						return (currentSum += parseInt(number.montoPagado));
					}, 0);

					let m2027 = store.pago2027.reduce((currentSum, number) => {
						return (currentSum += parseInt(number.montoPagado));
					}, 0);

					return (
						m2019 +
						m2020 +
						m2021 +
						m2022 +
						m2023 +
						m2024 +
						m2025 +
						m2026 +
						m2027
					);
				}

				if (meses == false) {
					if (store.infoClienteDt.mesesPagados2023 != null) {
						let mesesPagados = parseInt(store.infoClienteDt.mesesPagados2023);
						return mesesPagados;
					} else {
						let uno = 0;
						return uno;
					}
				}
			} else if (store.infoClienteDt.vigente === "No") {
				if (
					store.pago2019 != null &&
					store.pago2020 != null &&
					store.pago2021 != null &&
					store.pago2022 != null &&
					store.pago2023 != null &&
					store.pago2024 != null &&
					store.pago2025 != null &&
					store.pago2026 != null &&
					store.pago2027 != null &&
					meses == true
				) {
					let cero = 0;
					return cero;
				}

				if (meses == false) {
					if (store.infoClienteDt.mesesPagados2023 != null) {
						let mesesPagados = parseInt(store.infoClienteDt.mesesPagados2023);
						return mesesPagados;
					} else {
						let uno = 0;
						return uno;
					}
				}
			}
		};

		/* 		console.log("Saldo Deberia 1° " + SaldoDeberia(false));
		console.log("Saldo Total 2° " + SaldoTotal(false));
		console.log("Saldo Deberia2023 3° " + SaldoDeberia2023(false));
		console.log("Saldo Total2023 4° " + SaldoTotal2023(false)); */

		//Aquí se entrega el monto final calculado el debería con el real
		if (
			parseInt(SaldoDeberia(false)) +
				parseInt(SaldoDeberia2023(false)) -
				parseInt(SaldoTotal(false)) -
				parseInt(SaldoTotal2023(false)) <
			0
		) {
			return "Todo Pagado";
		} else {
			return (parseInt(SaldoDeberia(false)) - parseInt(SaldoTotal(false))) *
				9900 +
				(parseInt(SaldoDeberia2023(false)) - parseInt(SaldoTotal2023(false))) *
					11990 >
				2090
				? (parseInt(SaldoDeberia(false)) - parseInt(SaldoTotal(false))) * 9900 +
						(parseInt(SaldoDeberia2023(false)) -
							parseInt(SaldoTotal2023(false))) *
							11990
				: "Pagado";
		}
	};

	return (
		<div className='cell small-6'>
			<div className='card' style={{ width: "99%" }}>
				<div className='card-divider no-print'>
					<h4>Informacion de Cliente</h4>
				</div>
				<div className='card-section'>
					{store.infoClienteDt != null ? (
						<ul className='no-bullet'>
							<li>Id:{store.infoClienteDt.id}</li>
							<li>Razon Social: {store.infoClienteDt.razon}</li>
							<li>Rut Empresa: {store.infoClienteDt.rut}</li>
							<li>
								Nombre de Representante: {store.infoClienteDt.representante}
							</li>
							<li>Rut Representante: {store.infoClienteDt.rutRepresentante}</li>
							<li>
								Fecha Contratacion: {store.infoClienteDt.fechaContratacion}
							</li>
							<li className='no-print'>Erpyme: {store.infoClienteDt.erpyme}</li>
							<li className='no-print'>
								Contrato Actualizado: {store.infoClienteDt.contratoActualizado}
							</li>
							<li className='no-print'>Dicom: {store.infoClienteDt.dicom}</li>
							<li className='no-print'>
								Vigente: {store.infoClienteDt.vigente}
							</li>
							<li>Patente: {store.infoClienteDt.patente}</li>
							<li>Correo: {store.infoClienteDt.correo}</li>
							<li>Fono: {store.infoClienteDt.fono}</li>
							<li>
								Meses Pagados Sist. Antiguo: {store.infoClienteDt.mesesPagados}{" "}
								({CalculoSaldo("SaldoDeberia")})
							</li>
							<li>
								Meses Pagados Sist. Nuevo:{" "}
								{store.infoClienteDt.mesesPagados2023} (
								{CalculoSaldo("SaldoDeberia2023")})
							</li>
							<li>Saldo Total: ${CalculoSaldo()}</li>
						</ul>
					) : null}
				</div>
			</div>
		</div>
	);
};

export default InformacionCliente;
