import React, { useContext } from "react";
import { Context } from "./js/store/AppContext";
import { BrowserRouter, Route, Routes, useParams } from "react-router-dom";
import injectContext from "./js/store/AppContext";
import { Login } from "./js/views/Login";
import Administracion from "./js/views/Administracion";
import AdministracionVentas from "./js/views/AdministracionVentas";
import GeneradorDocumentos from "./js/views/GeneradorDocumentos";
import DireccionTributaria from "./js/views/DireccionTributaria";
import Contabilidad from "./js/views/Contabilidad";
import Ventas from "./js/views/Ventas";
import PaginaNoEncontrada from "./js/views/PaginaNoEncontrada";
import Navbar from "./js/component/Navbar";
import Spinner from "./js/Helper/Spinner";
import "foundation-sites/dist/css/foundation.min.css";
import "./styles/App.css";

const Layout = () => {
	const { store } = useContext(Context);
	const basename = process.env.BASENAME || "";

	return (
		<div className={store.witch ? "off-canvas-wrapper Spinner" : "Spinner"}>
			<div
				className={
					store.witch
						? "off-canvas-wrapper SpinnerBase"
						: "SpinnerBase Principal"
				}
				data-off-canvas-wrapper
			>
				<BrowserRouter basename={basename}>
					<Navbar />
					<div
						className={store.witch ? "off-canvas-content" : "RoutesSecundario"}
						data-off-canvas-content
					>
						<Routes className='Routes'>
							<Route path='/' element={<Login />}></Route>
							<Route
								path='/GeneradorDocumentos'
								element={<GeneradorDocumentos />}
							></Route>
							<Route
								path='/DireccionTributaria'
								element={<DireccionTributaria />}
							></Route>
							<Route path='/Contabilidad' element={<Contabilidad />}></Route>
							<Route
								path='/Administracion'
								element={<Administracion />}
							></Route>
							<Route path='/Ventas/:nombre' element={<Ventas />}></Route>
							<Route
								path='/AdministracionVentas'
								element={<AdministracionVentas />}
							></Route>
							<Route exact path='*' element={<PaginaNoEncontrada />}></Route>
						</Routes>
					</div>
				</BrowserRouter>
			</div>
			{store.spinner ? (
				<div className='SpinnerCharge'>
					<div className='SpinnerChargeSub'>
						<Spinner />
					</div>
				</div>
			) : null}
		</div>
	);
};

export default injectContext(Layout);
