import React, { PureComponent, useContext, useEffect, useState } from "react";
import { Context } from "../../../store/AppContext";
import { BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid } from "recharts";
import { ComposedChart, Legend } from "recharts";
import { PieChart, Pie, Line } from "recharts";

const DetalleEstadisticas = ({
	estadisticasDetalle,
	setEstadisticasDetalle,
	mes,
	year,
}) => {
	const { store, actions } = useContext(Context);
	const data = store.ventasUsuarioEstadisticas;

	const HandlerCerrar = (e) => {
		setEstadisticasDetalle(false);
	};

	/* Conteo Facturas Pendientes */
	const conteoFacturasPendientes = (objeto, i) => {
		return (
			<p key={i} style={{ display: "inline", fontSize: "22px" }}>
				{objeto.nombre}: {objeto.facturasPendientes} &nbsp; &nbsp;
			</p>
		);
	};

	/* KPI y diferencia Sueldo Liquido + Gasto Ads */
	const kpiListado = (objeto, i) => {
		return (
			<div className='callout' key='i'>
				<p style={{}}>
					{objeto.nombre}: {objeto.kpi}
				</p>
				<p>
					Utilidad aprox:&nbsp;
					{new Intl.NumberFormat("es-CL", {
						currency: "CLP",
						style: "currency",
					}).format(
						objeto.ventasTotales -
							(objeto.sueldoLiquido + objeto.adsPorVendedor)
					)}
				</p>
			</div>
		);
	};

	/* Comision Base y Sueldo Liquido */
	const sueldosCalculados = (objeto, i) => {
		return (
			<div className='callout' key='i'>
				<p style={{}}>{objeto.nombre}</p>
				<p>
					BONO DE PRODUCCION:&nbsp;
					{new Intl.NumberFormat("es-CL", {
						currency: "CLP",
						style: "currency",
					}).format(objeto.comisionBase * 400)}{" "}
					<br />
					SUELDO LIQUIDO APROX:&nbsp;
					{new Intl.NumberFormat("es-CL", {
						currency: "CLP",
						style: "currency",
					}).format(objeto.sueldoLiquido)}
				</p>
			</div>
		);
	};

	/* Grafico Venta y Gasto Ads */
	const graficoVentaGastoAds = (
		<BarChart
			width={590}
			height={400}
			data={data}
			margin={{
				top: 20,
				right: 20,
				bottom: 20,
				left: 20,
			}}
		>
			<CartesianGrid strokeDasharray='3 3' />
			<XAxis dataKey='nombre' allowDataOverflow={true} />
			<YAxis />
			<Tooltip />
			<Legend />
			<Bar dataKey='ventasTotales' fill='#413ea0' />
		</BarChart>
	);

	/* Grafico Venta + Sueldo Liquido + Gasto Ads */
	const graficoVentaSueldoGasto = (
		<BarChart
			width={750}
			height={600}
			data={data}
			margin={{
				top: 20,
				right: 30,
				left: 20,
				bottom: 5,
			}}
		>
			<CartesianGrid strokeDasharray='3 3' />
			<XAxis dataKey='nombre' />
			<YAxis />
			<Tooltip />
			<Legend />
			<Bar dataKey='sueldoLiquido' stackId='a' fill='#8884d8' />
			<Bar dataKey='adsPorVendedor' stackId='a' fill='#82ca9d' />
			<Bar dataKey='ventasTotales' fill='#ffc658' />
		</BarChart>
	);

	/* Sueldo Liquido vs Posibles */

	const renderPosiblesPorVendedor = (
		<BarChart
			width={700}
			height={600}
			data={data}
			margin={{
				top: 20,
				right: 30,
				left: 20,
				bottom: 5,
			}}
		>
			<CartesianGrid strokeDasharray='3 3' />
			<XAxis dataKey='nombre' />
			<YAxis yAxisId='left' orientation='left' stroke='#8884d8' />
			<YAxis yAxisId='right' orientation='right' stroke='#82ca9d' />
			<Tooltip />
			<Legend />
			<Bar yAxisId='left' dataKey='sueldoLiquido' fill='#8884d8' />
			<Bar yAxisId='right' dataKey='posiblesPorVendedor' fill='#82ca9d' />
		</BarChart>
	);

	return (
		<div className='row'>
			<div className='callout' data-closable>
				<button
					className='close-button'
					aria-label='Dismiss alert'
					type='button'
					data-close
					onClick={(e) => HandlerCerrar(e)}
				>
					<span aria-hidden='true'>×</span>
				</button>
				<br />
				<div className=''>
					<div className='row'>
						<h3>
							Estadisticas de Vendedores para {mes} del {year}
						</h3>
						<br />
						<div className='row'>
							<div className='callout'>
								<p>Facturas Pendientes</p>
								{store.ventasUsuarioEstadisticas != null &&
									store.ventasUsuarioEstadisticas.map((objeto, i) =>
										conteoFacturasPendientes(objeto, i)
									)}
							</div>
						</div>
						<br />
						<div className='columns small-12'>
							<div className='columns small-6'>
								<p>Ventas Totales por Vendedor</p>
								{graficoVentaGastoAds}
							</div>
							<div className='columns small-6'>
								<p>Ventas de Totales Este Mes</p>
								<p style={{ fontSize: "43px" }}>
									{store.ventasUsuarioEstadisticas != null &&
										new Intl.NumberFormat("es-CL", {
											currency: "CLP",
											style: "currency",
										}).format(
											store.ventasUsuarioEstadisticas.reduce(
												(acumulador, objeto) =>
													acumulador + objeto.ventasTotales,
												0
											)
										)}
								</p>
								<p>Ventas mes pasado: $xx.xxx.xxx</p>
								<p>Venta promedio 6 meses: $xx.xxx.xxx</p>
								<p>Ventas por cumplir: $xx.xxx.xxx</p>
								<p>Venta proyectada este mes: $xx.xxx.xxx</p>
							</div>
						</div>
						<br />
						<hr />
						<div className='columns small-12'>
							<div className='columns small-8'>
								<p>Sueldo Liquido + Gasto Ads v/s Ventas por Vendedor</p>
								{graficoVentaSueldoGasto}
							</div>
							<div className='columns small-4'>
								<p>KPI</p>
								{store.ventasUsuarioEstadisticas != null &&
									store.ventasUsuarioEstadisticas.map((objeto, i) =>
										kpiListado(objeto, i)
									)}
							</div>
						</div>
						<br />
						<hr />
						<div className='columns small-12'>
							<div className='columns small-8'>
								<p>Sueldo Liquido y Posibles por Vendedor</p>
								{renderPosiblesPorVendedor}
							</div>
							<div className='columns small-4'>
								<p>
									Comisiones ventas {mes} {year}
								</p>
								{store.ventasUsuarioEstadisticas != null &&
									store.ventasUsuarioEstadisticas.map((objeto, i) =>
										sueldosCalculados(objeto, i)
									)}
							</div>
						</div>
						<br />
					</div>
				</div>
			</div>
		</div>
	);
};

export default DetalleEstadisticas;
