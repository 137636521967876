import React, { useEffect, useRef, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { Context } from "../store/AppContext";
import ExcelVentas0 from "../component/Ventas/ExcelVentas/ExcelVentas0";
import ExcelVentas1 from "../component/Ventas/ExcelVentas/ExcelVentas1";
import ExcelVentas2 from "../component/Ventas/ExcelVentas/ExcelVentas2";
import ExcelVentas3 from "../component/Ventas/ExcelVentas/ExcelVentas3";
import ExcelVentas4 from "../component/Ventas/ExcelVentas/ExcelVentas4";
import ExcelVentas5 from "../component/Ventas/ExcelVentas/ExcelVentas5";
import ExcelVentas6 from "../component/Ventas/ExcelVentas/ExcelVentas6";
import ExcelVentas7 from "../component/Ventas/ExcelVentas/ExcelVentas7";
import ExcelVentas8 from "../component/Ventas/ExcelVentas/ExcelVentas8";
import ExcelVentas9 from "../component/Ventas/ExcelVentas/ExcelVentas9";
import ExcelVentas10 from "../component/Ventas/ExcelVentas/ExcelVentas10";
import ExcelVentas11 from "../component/Ventas/ExcelVentas/ExcelVentas11";
import BotonOtrosMeses from "../component/Ventas/BotonOtrosMeses";

const Ventas = () => {
	const { store, actions } = useContext(Context);
	const [mes, setMes] = useState(0);
	let { nombre } = useParams(); /* No Borrar */
	const [mes0, setMes0] = useState(false); /* detecta cambios en el mes */
	const [mesy, setMesy] =
		useState(true); /* Nada aun, pero detecta unmount cambiando pesta;a */
	const [mes1, setMes1] = useState(false);
	const [mes2, setMes2] = useState(false);
	const [mes3, setMes3] = useState(false);
	const [mes4, setMes4] = useState(false);
	const [mes5, setMes5] = useState(false);
	const [mes6, setMes6] = useState(false);
	const [mes7, setMes7] = useState(false);
	const [mes8, setMes8] = useState(false);
	const [mes9, setMes9] = useState(false);
	const [mes10, setMes10] = useState(false);
	const [mes11, setMes11] = useState(false);

	useEffect(() => {
		if (sessionStorage.getItem("dateVentasMeses") === null) {
			actions.getVentaUsuarioEspecifico(store.usuarioActual.id);
		}
	}, []);

	/* Este ejecuta cuando se cambia de pestaña a pestaña */
	useEffect(() => {
		if (!mesy) {
			console.log(`useEffect ${mesy}`);
		}
	}, [mesy]);

	const mesYear = () => {
		if (store.ventasUsuarioVendedores != null) {
			if (mes === 0) {
				return `${store.ventasUsuarioVendedores[0].mesIngreso} ${store.ventasUsuarioVendedores[0].yearIngreso}`;
			}
			if (mes === 1) {
				return `${store.ventasUsuarioVendedores[1].mesIngreso} ${store.ventasUsuarioVendedores[1].yearIngreso}`;
			}
			if (mes === 2) {
				return `${store.ventasUsuarioVendedores[2].mesIngreso} ${store.ventasUsuarioVendedores[2].yearIngreso}`;
			}
			if (mes === 3) {
				return `${store.ventasUsuarioVendedores[3].mesIngreso} ${store.ventasUsuarioVendedores[3].yearIngreso}`;
			}
			if (mes === 4) {
				return `${store.ventasUsuarioVendedores[4].mesIngreso} ${store.ventasUsuarioVendedores[4].yearIngreso}`;
			}
			if (mes === 5) {
				return `${store.ventasUsuarioVendedores[5].mesIngreso} ${store.ventasUsuarioVendedores[5].yearIngreso}`;
			}
			if (mes === 6) {
				return `${store.ventasUsuarioVendedores[6].mesIngreso} ${store.ventasUsuarioVendedores[6].yearIngreso}`;
			}
			if (mes === 7) {
				return `${store.ventasUsuarioVendedores[7].mesIngreso} ${store.ventasUsuarioVendedores[7].yearIngreso}`;
			}
			if (mes === 8) {
				return `${store.ventasUsuarioVendedores[8].mesIngreso} ${store.ventasUsuarioVendedores[8].yearIngreso}`;
			}
			if (mes === 9) {
				return `${store.ventasUsuarioVendedores[9].mesIngreso} ${store.ventasUsuarioVendedores[9].yearIngreso}`;
			}
			if (mes === 10) {
				return `${store.ventasUsuarioVendedores[10].mesIngreso} ${store.ventasUsuarioVendedores[10].yearIngreso}`;
			}
			if (mes === 11) {
				return `${store.ventasUsuarioVendedores[11].mesIngreso} ${store.ventasUsuarioVendedores[11].yearIngreso}`;
			}
		}
	};

	return (
		<div className='columns small-12'>
			{store.ventasUsuarioVendedores != null &&
			store.ventasUsuarioVendedores[0] != undefined ? (
				<>
					<h2 className='columns small-12'>
						Tabla de Ventas de {store.usuarioActual.nombre} mes de {}
						{mesYear()} &nbsp;
						<BotonOtrosMeses mes={mes} setMes={setMes} />
					</h2>

					<hr />
					<>
						{mes === 0 && (
							<ExcelVentas0 mes0={mes0} setMes0={setMes0} setMesy={setMesy} />
						)}
						{mes === 1 && (
							<ExcelVentas1 mes1={mes1} setMes1={setMes1} setMesy={setMesy} />
						)}
						{mes === 2 && (
							<ExcelVentas2 mes2={mes2} setMes2={setMes2} setMesy={setMesy} />
						)}
						{mes === 3 && (
							<ExcelVentas3 mes3={mes3} setMes3={setMes3} setMesy={setMesy} />
						)}
						{mes === 4 && (
							<ExcelVentas4 mes4={mes4} setMes4={setMes4} setMesy={setMesy} />
						)}
						{mes === 5 && (
							<ExcelVentas5 mes5={mes5} setMes5={setMes5} setMesy={setMesy} />
						)}
						{mes === 6 && (
							<ExcelVentas6 mes6={mes6} setMes6={setMes6} setMesy={setMesy} />
						)}
						{mes === 7 && (
							<ExcelVentas7 mes7={mes7} setMes7={setMes7} setMesy={setMesy} />
						)}
						{mes === 8 && (
							<ExcelVentas8 mes8={mes8} setMes8={setMes8} setMesy={setMesy} />
						)}
						{mes === 9 && (
							<ExcelVentas9 mes9={mes9} setMes9={setMes9} setMesy={setMesy} />
						)}
						{mes === 10 && (
							<ExcelVentas1
								mes10={mes10}
								setMes10={setMes10}
								setMesy={setMesy}
							/>
						)}
						{mes === 11 && (
							<ExcelVentas1
								mes11={mes11}
								setMes11={setMes11}
								setMesy={setMesy}
							/>
						)}
					</>
				</>
			) : (
				<>
					<h1>Tu información está cargando</h1>
					<p>
						Si este mensaje no desaparece a los pocos segundos y no puedes ver
						tus ventas, comunicate con alguno de tus superiores.
					</p>
				</>
			)}
		</div>
	);
};

export default Ventas;
