const FormularioIngresoDatos = ({ datos, setDatos }) => {
	const HandlerCompletarDatos = (event) => {
		setDatos({ ...datos, [event.target.name]: event.target.value });
	};

	return (
		<div>
			Ingresa los datos solicitados
			<form
				className='log-in-form'
				style={{
					backgroundColor: "rgba(46, 138, 199, 0.25)",
					padding: "20px",
				}}
			>
				<div className='row'>
					<div className='columns small-12'>
						{/* Primer Bloque */}
						<div className='columns small-3'>
							<label style={{ color: "grey", textShadow: "1px 1px black" }}>
								Año de Ingreso
								<input
									type='number'
									placeholder='2035'
									name='yearIngreso'
									onChange={(e) => HandlerCompletarDatos(e)}
								/>
							</label>
							<label style={{ color: "grey", textShadow: "1px 1px black" }}>
								Mes de Ingreso
								<input
									type='text'
									placeholder='Enero'
									name='mesIngreso'
									onChange={(e) => HandlerCompletarDatos(e)}
								/>
							</label>
						</div>
						{/* Segundo Bloque */}
						<div className='columns small-3'>
							<label style={{ color: "grey", textShadow: "1px 1px black" }}>
								Gasto Ads Mes Pasado
								<input
									type='number'
									placeholder='1000000'
									name='gastoAds'
									onChange={(e) => HandlerCompletarDatos(e)}
								/>
							</label>
						</div>
						{/* Tercer Bloque */}
						<div className='columns small-3'>
							<label style={{ color: "grey", textShadow: "1px 1px black" }}>
								Total Posibles Mes Pasado
								<input
									type='number'
									placeholder='500'
									name='totalPosibles'
									onChange={(e) => HandlerCompletarDatos(e)}
								/>
							</label>
						</div>
						{/* Cuarto Bloque */}
						<div className='columns small-3'>
							<label style={{ color: "grey", textShadow: "1px 1px black" }}>
								Sueldo Base para este Mes
								<input
									type='number'
									placeholder='400000'
									name='sueldoBase'
									onChange={(e) => HandlerCompletarDatos(e)}
								/>
							</label>
						</div>
					</div>
				</div>
			</form>
		</div>
	);
};

export default FormularioIngresoDatos;
