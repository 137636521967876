import { useRef, useEffect, useState, useContext } from "react";
import { Context } from "../../../store/AppContext";
import jspreadsheet from "jspreadsheet-ce";
import "../../../../../node_modules/jspreadsheet-ce/dist/jspreadsheet.css";
import OpcionesNuevo from "./FuncionesExcel";
import Alerta from "./Alerta";
import Estadisticas from "../Estadisticas";
import DiasHabiles from "./DiasHabiles";

function ExcelVentas6({ mes6, setMes6, setMesy }) {
	const { store, actions } = useContext(Context);
	const jRef = useRef(null);
	const mesIngreso = null;
	const yearIngreso = null;
	const puntosVentas = {
		"Plan 1": 4,
		"Plan 2": 10,
		"Plan 3": 10,
		"Plan 4": 12.5,
		"Plan 5": 8,
		"Plan 6": 10,
		"Plan 7": 10,
		"Plan 8": 12.5,
		"Dt Mensual": 6,
		"Dt Anual": 10,
		"Conta Básico": 9,
		"Conta Emprende": 10.5,
		"Conta Plan Pyme": 15.5,
		"Conta Remuneraciones": 9,
		"Pagina Web": 10,
		"Modif 1 dia": 12.5,
		"Modif Tradicional": 12.5,
		"Notificación SII": 4,
		Impresora: 4,
		Patente: 4,
		Otros: 3,
		"Renta Clientes": 5,
		"Renta No Clientes": 10,
		"Hab. Facturas": 10,
		"Hab. Boletas": 10,
		"Hab. Facturas + Boletas": 12.5,
		"Inicio Activ": 3,
		"": 0,
	};
	const [ventasTotales, setVentasTotales] = useState(
		store.ventasUsuarioVendedores[6].ventasTotales
	);
	const [ventasProyectadas, setVentasProyectadas] =
		useState(0); /* Calculo en Front */
	const [facturasPendientes, setFacturasPendientes] = useState(
		store.ventasUsuarioVendedores[6].facturasPendientes
	);
	const [kpi, setKpi] = useState(store.ventasUsuarioVendedores[6].kpi);
	const [kpiProyectado, setKpiProyectado] = useState(0); /* Calculo en front */
	const [comisionBase, setComisionBase] = useState(
		store.ventasUsuarioVendedores[6].comisionBase
	);
	const [cambios, setCambios] = useState(false);
	const gastoAds = store.ventasUsuarioVendedores[6].gastoAds;
	const totalPosibles = store.ventasUsuarioVendedores[6].totalPosibles;
	const adsPorVendedor = store.ventasUsuarioVendedores[6].adsPorVendedor;
	const posiblesPorVendedor =
		store.ventasUsuarioVendedores[6].posiblesPorVendedor;
	const sueldoBase = store.ventasUsuarioVendedores[6].sueldoBase;
	const ventasMesPrevio = store.ventasUsuarioVendedores[6].ventasTotalesPasado;
	const [sueldoLiquido, setSueldoLiquido] = useState(
		store.ventasUsuarioVendedores[6].sueldoLiquido
	);

	/* Ante cada cambio, se guarda la info en Local */
	const changed = (instance, cell, x, y, value) => {
		sessionStorage.setItem(
			"ventasMes6",
			JSON.stringify(jRef.current.jexcel.getData())
		);

		if (x === "1") {
			let monto = value;
			let primerPago = jRef.current.jexcel.getValueFromCoords(6, y);
			jRef.current.jexcel.setValueFromCoords(
				7,
				y,
				parseInt(value) - primerPago,
				true
			);
		}

		if (x === "6") {
			let monto = value;
			let primerPago = jRef.current.jexcel.getValueFromCoords(1, y);
			jRef.current.jexcel.setValueFromCoords(
				7,
				y,
				primerPago - parseInt(value),
				true
			);
		}

		setMes6(true);
		setCambios(true);
	};

	/* Pasar estas funciones a otro archivo problema con useRef */
	const guardar = () => {
		/* Guardar Info en BD */
		actions.editarVentaEspecifica(
			store.ventasUsuarioVendedores[6].id,
			mesIngreso,
			yearIngreso,
			JSON.stringify(jRef.current.jexcel.getData()),
			ventasTotales,
			ventasMesPrevio,
			kpi,
			gastoAds,
			totalPosibles,
			adsPorVendedor,
			posiblesPorVendedor,
			facturasPendientes,
			sueldoBase,
			comisionBase,
			sueldoLiquido
		);
		setMes6(false);
	};

	const undo = () => {
		jRef.current.jexcel.undo();
		setMes6(true);
	};

	const redo = () => {
		jRef.current.jexcel.redo();
		setMes6(true);
	};

	const addRow = () => {
		/* Agrega 5 rows al final */
		jRef.current.jexcel.insertRow(5);
		setMes6(true);
	};

	const lessRow = () => {
		/* Elimina rows al final */
		jRef.current.jexcel.deleteRow();
		setMes6(true);
	};

	/* Esto eventualmente podría ser cambiado por un CustomHook */
	const options = {
		/* Opciones de la Tabla */
		data:
			sessionStorage.getItem("ventasMes6") != null
				? JSON.parse(sessionStorage.getItem("ventasMes6"))
				: JSON.parse(store.ventasUsuarioVendedores[6].ventas),
		minDimensions: [17, 30],
		tableOverflow: true,
		freezeRows: 1,
		freezeColumns: 2,
		tableWidth: "90%",
		tableHeight: "70%",
		contextMenu: false,
		columns: [
			{ type: "text", title: "Nombre Cliente", width: 200 },
			{ type: "numeric", title: "Monto", width: 80 },
			{
				type: "dropdown",
				title: "Servicio",
				width: 100,
				source: [
					"Plan 1",
					"Plan 2",
					"Plan 3",
					"Plan 4",
					"Plan 5",
					"Plan 6",
					"Plan 7",
					"Plan 8",
					"Modif 1 dia",
					"Modif Tradicional",
					"Notificación SII",
					"Dt Mensual",
					"Dt Anual",
					"Conta Básico",
					"Conta Emprende",
					"Conta Emprende Plus",
					"Conta Plan Pyme",
					"Conta Remuneraciones",
					"Web",
					"Patente",
					"Otros",
					"Impresora",
					"Renta Clientes",
					"Renta No Clientes",
					"Hab. Facturas",
					"Hab. Boletas",
					"Hab. Facturas + Boletas",
					"Inicio Activ",
				],
				autocomplete: true,
			},
			{ type: "numeric", title: "S", width: 40 },
			{
				type: "dropdown",
				title: "Forma Pago",
				width: 100,
				source: [
					"Transferencia",
					"T. Credito",
					"Efectivo",
					"Deposito",
					"Gratis",
					"Contrato",
				],
				autocomplete: true,
			},
			{ type: "text", title: "Email", width: 80 },
			{ type: "numeric", title: "1° Pago", width: 80 },
			{ type: "numeric", title: "Saldo", width: 80 },
			{ type: "text", title: "Razon", width: 80 },
			{
				type: "numeric",
				title: "Cotiz",
				width: 80,
			},
			{ type: "numeric", title: "Factura", width: 80 },
			{ type: "checkbox", title: "Patente", width: 80 },
			{ type: "checkbox", title: "Conta", width: 80 },
			{ type: "text", title: "Libre", width: 150 },
		],
		footers: [["Total", "=SUM(B1:B1000)"]],
		onchange: changed,
	};

	useEffect(() => {
		/* Carga la tabla */
		if (!jRef.current.jspreadsheet) {
			jspreadsheet(jRef.current, options);
		}
	}, [options]);

	useEffect(() => {
		/* Suma Ventas */
		const sum = jRef.current.jexcel
			.getColumnData([1])
			.reduce((partialSum, a) => partialSum + (a != "" ? parseInt(a) : 0), 0);
		setVentasTotales(sum);

		/* Facturas Pendietes */
		const facturas =
			jRef.current.jexcel.getColumnData([0]).filter(function (x) {
				return x != "";
			}).length -
			jRef.current.jexcel.getColumnData([10]).filter(function (x) {
				return x != "";
			}).length;
		setFacturasPendientes(facturas);

		/* Kpi */
		const kpiCalculado =
			sum / (posiblesPorVendedor * (gastoAds / totalPosibles));
		setKpi(kpiCalculado);

		/* Ventas Proyectadas */
		var date = new Date();
		var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
		var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
		const newSum =
			(sum / DiasHabiles(firstDay, date)) * DiasHabiles(firstDay, lastDay);
		setVentasProyectadas(newSum);

		/* KPi Proyectado */
		const kpiCalculadoProyectado =
			newSum / (posiblesPorVendedor * (gastoAds / totalPosibles));
		setKpiProyectado(kpiCalculadoProyectado);

		/* Comision Base */
		let sumaComision = 0;
		for (let i = 0; i < jRef.current.jexcel.getColumnData([2]).length; i++) {
			sumaComision =
				sumaComision +
				(puntosVentas[jRef.current.jexcel.getColumnData([2])[i]] != NaN
					? puntosVentas[jRef.current.jexcel.getColumnData([2])[i]]
					: 0);
		}
		setComisionBase(sumaComision);

		/* sueldo Liquido */
		let sumaComisionLiquido = 0;
		for (let i = 0; i < jRef.current.jexcel.getColumnData([2]).length; i++) {
			sumaComisionLiquido =
				sumaComisionLiquido +
				(puntosVentas[jRef.current.jexcel.getColumnData([2])[i]] != NaN
					? puntosVentas[jRef.current.jexcel.getColumnData([2])[i]] * 400
					: 0);
		}
		setSueldoLiquido(sueldoBase + sumaComisionLiquido);

		setCambios(false);
	}, [cambios]);

	return (
		<>
			<Estadisticas
				ventasTotales={ventasTotales}
				ventasProyectadas={ventasProyectadas}
				facturasPendientes={facturasPendientes}
				kpi={kpi}
				kpiProyectado={kpiProyectado}
				comisionBase={comisionBase}
				sueldoBase={sueldoBase}
				ventasMesPrevio={ventasMesPrevio}
				sueldoLiquido={sueldoLiquido}
			/>
			<hr />
			<div className='columns small-12'>
				<OpcionesNuevo
					undo={undo}
					redo={redo}
					addRow={addRow}
					lessRow={lessRow}
					guardar={guardar}
					mes6={mes6}
				/>
				{mes6 && <Alerta />}
				<div ref={jRef} />
				<br />
				<OpcionesNuevo
					undo={undo}
					redo={redo}
					addRow={addRow}
					lessRow={lessRow}
					guardar={guardar}
					mes6={mes6}
				/>
			</div>
		</>
	);
}

export default ExcelVentas6;
