import React, { useState, useContext } from "react";
import { Context } from "../../../store/AppContext";

const ListaVendedores = ({
	listaVendedores,
	setListaVendedores,
	listaVendedoresNombre,
	setListaVendedoresNombre,
}) => {
	const { store, actions } = useContext(Context);

	const HandlerAgregandoVendedores = (e) => {
		console.log(e.target.name);
		if (listaVendedores.includes(e.target.id)) {
			setListaVendedores(
				listaVendedores.filter((vendedor) => vendedor != e.target.id)
			);
			setListaVendedoresNombre(
				listaVendedoresNombre.filter((vendedor) => vendedor != e.target.name)
			);
		} else {
			setListaVendedores([...listaVendedores, e.target.id]);
			setListaVendedoresNombre([...listaVendedoresNombre, e.target.name]);
		}
	};

	const ListaDesplegarUsuarios = (objeto, i) => {
		if (objeto.tipo === "Super Administrador") {
			return null;
		}

		return (
			<>
				<input
					id={objeto.id}
					type='checkbox'
					onClick={(e) => HandlerAgregandoVendedores(e)}
					name={`${objeto.nombre} ${objeto.apellido}`}
				/>
				<label htmlFor={objeto.id}>
					{objeto.nombre} {objeto.apellido}
				</label>
				{i % 5 === 0 && <br></br>}
			</>
		);
	};

	return (
		<div>
			Lista de Vendedores
			<fieldset className='fieldset'>
				<legend>Marca quienes tendrán comisiones este nuevo mes</legend>
				{store.usuarios != null &&
					store.usuarios.map((objeto, i) => ListaDesplegarUsuarios(objeto, i))}
			</fieldset>
		</div>
	);
};

export default ListaVendedores;
