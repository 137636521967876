import React, { useState, useContext, useEffect } from "react";
import { Context } from "../../../../store/AppContext";

const RespuestaCrearCliente = ({
	setNuevoCliente,
	setRespuesta,
	animarModal,
	setAnimarModal,
	setClienteDtCliqueado,
	setClienteSeleccionado,
}) => {
	const { store, actions } = useContext(Context);

	useEffect(() => {
		setAnimarModal(true);
	}, []);

	const HandlerCerrar = () => {
		setAnimarModal(false);
		setTimeout(() => {
			setRespuesta(false);
			setNuevoCliente(false);
		}, 500);
	};

	const HandlerCrearOtroCliente = () => {
		setAnimarModal(false);
		setTimeout(() => {
			setRespuesta(false);
			setTimeout(() => {
				setNuevoCliente(true);
				setAnimarModal(true);
			}, 200);
		}, 500);
	};

	const DatosClienteCreado = () => {
		return (
			<div>
				<h1>Haz Creado un Nuevo Cliente</h1>
				<hr />
				<br />
				<h4>Datos</h4>
				<div>
					<h5 style={{ display: "inline" }}>Razon Social: </h5>{" "}
					<span>{store.response.razon}</span>{" "}
				</div>
				<div>
					<h5 style={{ display: "inline" }}>Rut: </h5>{" "}
					<span>{store.response.rut}</span>{" "}
				</div>
				<div>
					<h5 style={{ display: "inline" }}>id: </h5>{" "}
					<span>{store.response.id}</span>{" "}
				</div>
				<div>
					<h5 style={{ display: "inline" }}>Fecha Contratación: </h5>{" "}
					<span>{store.response.fechaContratacion}</span>{" "}
				</div>
				<div>
					<h5 style={{ display: "inline" }}>Correo principal: </h5>{" "}
					<span>{store.response.correo}</span>{" "}
				</div>
				<br />
				<hr />
			</div>
		);
	};

	const HandlerVerClienteCreado = () => {
		setClienteDtCliqueado(store.response);
		setAnimarModal(false);
		setTimeout(() => {
			setRespuesta(false);
			setNuevoCliente(false);
			setClienteSeleccionado(true);
		}, 500);
	};

	return (
		<div className={`formulario ${animarModal ? "animar" : "cerrar"}`}>
			<div style={{ display: "grid", placeContent: "center", color: "white" }}>
				{store.response != null && <DatosClienteCreado />}
				<div className='row'>
					<div className='columns small-12'>
						<div className='columns small-4'>
							<button
								className='success button'
								onClick={(e) => HandlerCrearOtroCliente(e)}
							>
								Crear Otro Cliente
							</button>
						</div>
						<div className='columns small-4'>
							<button
								className='warning button'
								onClick={(e) => HandlerVerClienteCreado(e)}
							>
								Ver Cliente Creado
							</button>
						</div>
						<div className='columns small-4'>
							<button className='button' onClick={(e) => HandlerCerrar(e)}>
								Cerrar Ventana
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default RespuestaCrearCliente;
