import React, { useState } from "react";
import IngresoDatos from "../component/AdministracionVentas/IngresoDatos";
import ModificarIngresoDatos from "../component/AdministracionVentas/ModificarIngresoDatos";
import EstadisticasVendedores from "../component/AdministracionVentas/EstadisticasVendedores";
import EstadisticasAmpliadas from "../component/AdministracionVentas/EstadisticasAmpliadas";
import VentasVendedores from "../component/AdministracionVentas/VentasVendedores";
import Head from "../component/Head";

const AdministracionVentas = () => {
	const titulosHead = [
		"Bienvenido a la Administracion de Ventas",
		"Podrás revisar las estadisticas de los vendedores, sus ventas y crear meses adicionales.",
	];

	const [ingresoDatos, setIngresoDatos] = useState(false);
	const [modificarIngresoDatos, setModificarIngresoDatos] = useState(false);
	const [estadisticas, setEstadisticas] = useState(false);
	const [estadisticasAmpliadas, setEstadisticasAmpliadas] = useState(false);
	const [ventasVendedores, setVentasVendedores] = useState(false);

	const HandlerIngresoDatos = () => {
		setIngresoDatos(true);
	};

	const HandlerModificarIngresoDatos = () => {
		setModificarIngresoDatos(true);
	};

	const HandlerEstadisticas = () => {
		setEstadisticas(true);
	};

	const HandlerVentasVendedores = () => {
		setVentasVendedores(true);
	};

	const HandlerEstadisticasAmpliadas = () => {
		setEstadisticasAmpliadas(true);
	};

	return (
		<div>
			<Head contenido={titulosHead} />
			{ingresoDatos && (
				<IngresoDatos
					ingresoDatos={ingresoDatos}
					setIngresoDatos={setIngresoDatos}
				/>
			)}
			{modificarIngresoDatos && (
				<ModificarIngresoDatos
					setModificarIngresoDatos={setModificarIngresoDatos}
				/>
			)}
			{estadisticas && (
				<EstadisticasVendedores
					estadisticas={estadisticas}
					setEstadisticas={setEstadisticas}
				/>
			)}
			{ventasVendedores && (
				<VentasVendedores
					ventasVendedores={ventasVendedores}
					setVentasVendedores={setVentasVendedores}
				/>
			)}
			{estadisticasAmpliadas && (
				<EstadisticasAmpliadas
					estadisticasAmpliadas={estadisticasAmpliadas}
					setEstadisticasAmpliadas={setEstadisticasAmpliadas}
				/>
			)}
			{!ingresoDatos &&
				!modificarIngresoDatos &&
				!estadisticas &&
				!ventasVendedores &&
				!estadisticasAmpliadas && (
					<div className='row'>
						<div className='columns small-12'>
							<div className='callout columns small-12'>
								<div className='columns small-12'>
									Agregar Información para Vendedores y al Sistema
								</div>
								&nbsp;
								<div className='columns small-12'>
									<div className='columns small-4'>
										<button
											className='button large'
											title='Cantidad de Posibles y Gasto en Ads'
											onClick={(e) => HandlerIngresoDatos(e)}
										>
											Agregar Info a Mes Nuevo
										</button>
									</div>
									<div className='columns small-4'>
										<button
											title='Modificar Posibles por Vendedor y otros'
											className='button large'
											onClick={(e) => HandlerModificarIngresoDatos(e)}
										>
											Modificar Info a Mes
										</button>
									</div>
									<div className='columns small-4'>&nbsp;</div>
								</div>
							</div>
							<div className='callout columns small-12'>
								<div className='columns small-12'>Otras Opciones</div>
								&nbsp;
								<div className='columns small-12'>
									<div className='columns small-4'>
										<button
											onClick={(e) => HandlerEstadisticas(e)}
											className='button large'
											title='Ver Estadisticas'
										>
											Estadisticas de Ventas
										</button>
									</div>
									<div className='columns small-4'>
										<button
											className='button large'
											title='Ver Estadisticas por 12 meses'
											onClick={(e) => HandlerEstadisticasAmpliadas(e)}
											disabled
										>
											Estadisticas Ampliadas
										</button>
									</div>
									<div className='columns small-4'>
										<button
											className='button large'
											title='Ver Ventas Individualmente'
											onClick={(e) => HandlerVentasVendedores(e)}
											disabled
										>
											Detalle de Ventas
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				)}
		</div>
	);
};

export default AdministracionVentas;
