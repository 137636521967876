import React from "react";
import { useNavigate } from "react-router-dom";
import ImagenNoEncontrada from "../../img/404error.webp";

const PaginaNoEncontrada = () => {
	const history = useNavigate();

	const HandlerClickInicio = () => {
		history("/");
	};

	return (
		<div className='row text-center'>
			<br />
			<img src={ImagenNoEncontrada} alt='' className='card-img-top' />
			<h3>Has llegado lejos... pero parece que estas un poco perdido, no?</h3>
			<h2>
				Error 404, <strong>Pagina no Encontrada</strong>
			</h2>
			<br />
			<br />
			<a
				className='button success large'
				onClick={(e) => HandlerClickInicio(e)}
			>
				Volver al Inicio
			</a>
		</div>
	);
};

export default PaginaNoEncontrada;
