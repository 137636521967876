import React, { useContext, useState, useEffect } from "react";
import { Context } from "../../../../store/AppContext";
import PaginadorContabilidad from "../../PaginadorContabilidad.js";
import ListaClientesContaCompleta from "./ListaClientesContaCompleta";
import ClienteSeleccionado from "../../ClienteSeleccionado.js";
import { ExportTableToExcel } from "../../../../Helper/ExportTableToExcel";

/* Busqueda con BD Completa */

const BusquedaCompleta = ({ setBusquedaCompleta, tipoFiltro }) => {
	const { store, actions } = useContext(Context);
	const [clienteSeleccionado, setClienteSeleccionado] = useState(false);
	const [clienteContaCliqueado, setClienteContaCliqueado] = useState(null);
	const [colores, setColores] = useState(false); //Controla la Aplicación de Colores en las tablas.
	const [listaClientes, setListaClientes] = useState(null);

	useEffect(() => {
		if (sessionStorage.getItem("listadoClientesConta") != null) {
			setListaClientes(
				JSON.parse(sessionStorage.getItem("listadoClientesConta"))
			);
			setTimeout(() => {
				actions.setSpinner(false);
			}, 100);
		} else if (sessionStorage.getItem("listadoClientesConta") === null) {
			actions.getBusquedaContabilidad("", tipoFiltro);
		}
	}, []);

	const HandlerExportarTabla = (event) => {
		ExportTableToExcel("xlsx");
	};

	const tituloListado = () => {
		if (sessionStorage.getItem("dateClientesConta") != null) {
			return (
				<>
					<h2>Lista de Clientes Contabilidad {tipoFiltro.toString()}s*</h2>
					<small>
						*Lista actualizada hasta el{" "}
						{sessionStorage.getItem("dateClientesConta")}
					</small>
				</>
			);
		}
	};

	return (
		<div>
			<div>
				<div className='no-print row button-group align-right'>
					<button
						className='clear button alert'
						onClick={(e) => setBusquedaCompleta(false)}
					>
						Regresar Panel Inicial
					</button>
					{clienteSeleccionado && (
						<>
							<a class='clear button' href='#' disabled>
								&#62;
							</a>

							<button
								className='clear button alert'
								onClick={(e) => setClienteSeleccionado(false)}
							>
								Regresar Listado Previo
							</button>
						</>
					)}
				</div>
				<>
					{/* Botones de Opciones */}
					<div className='row'>
						{clienteSeleccionado && (
							<ClienteSeleccionado
								setClienteSeleccionado={setClienteSeleccionado}
								clienteContaCliqueado={clienteContaCliqueado}
							/>
						)}
						{!clienteSeleccionado && (
							<>
								{tituloListado()}
								<div className='columns small-12'>
									<div className='columns small-9'>&nbsp;</div>
									<div className='columns small-1'>
										{/* Este es el Switch para la Aplicación de Colores */}
										<div>
											<div className='switch large'>
												<input
													className='switch-input'
													id='yes-noEspecifico'
													type='checkbox'
													name='exampleSwitch'
													checked={false}
												/>
												<label
													className='switch-paddle'
													htmlFor='yes-noEspecifico'
													disabled
												>
													<span className='show-for-sr'>Colores?</span>
													<span className='switch-active' aria-hidden='true'>
														Si
													</span>
													<span className='switch-inactive' aria-hidden='true'>
														No
													</span>
												</label>
											</div>
										</div>
									</div>
									<div className='columns small-2'>
										<button
											className='submit button expanded'
											onClick={(e) => HandlerExportarTabla(e)}
										>
											Exportar Seleccion
										</button>
									</div>
								</div>
							</>
						)}
					</div>
					<br />
					{!clienteSeleccionado && (
						<>
							<ListaClientesContaCompleta
								setClienteSeleccionado={setClienteSeleccionado}
								setClienteContaCliqueado={setClienteContaCliqueado}
								colores={colores}
								tipoFiltro={tipoFiltro}
							/>
							<div className='' style={{ textAlign: "center" }}>
								<br />
								<PaginadorContabilidad />
							</div>
						</>
					)}
				</>
			</div>
		</div>
	);
};

export default BusquedaCompleta;
