import { jsPDF } from "jspdf";
import formularioPatentePrimero from "../../../img/formularioPatentePrimero.jpg";
import formularioPatenteSegundo from "../../../img/formularioPatenteSegundo.jpg";
import formularioPatenteTercero from "../../../img/formularioPatenteTercero.jpg";

export const DocumentosPatente = (datosDocumentosPatente) => {
	let {
		nombre,
		rut,
		razon,
		rutEmpresa,
		actividadEspecifica,
		codigo1,
		giro1,
		codigo2,
		giro2,
		codigo3,
		giro3,
		numeroTrabajadoresInmueble,
		numeroTrabajadoresPais,
		capitalNegocio,
		...demas
	} = datosDocumentosPatente;

	/* Fecha */
	let fecha = new Date();
	let year = fecha.getFullYear() - 2020;
	let mes = "";
	if (fecha.getMonth() + 1 == 1) {
		mes = "Enero";
	} else if (fecha.getMonth() + 1 == 2) {
		mes = "Febrero";
	} else if (fecha.getMonth() + 1 == 3) {
		mes = "Marzo";
	} else if (fecha.getMonth() + 1 == 4) {
		mes = "Abril";
	} else if (fecha.getMonth() + 1 == 5) {
		mes = "Mayo";
	} else if (fecha.getMonth() + 1 == 6) {
		mes = "Junio";
	} else if (fecha.getMonth() + 1 == 7) {
		mes = "Julio";
	} else if (fecha.getMonth() + 1 == 8) {
		mes = "Agosto";
	} else if (fecha.getMonth() + 1 == 9) {
		mes = "Septiembre";
	} else if (fecha.getMonth() + 1 == 10) {
		mes = "Octubre";
	} else if (fecha.getMonth() + 1 == 11) {
		mes = "Noviembre";
	} else if (fecha.getMonth() + 1 == 12) {
		mes = "Diciembre";
	} else {
		mes = "Actual";
	}

	/* PDF */
	const doc = new jsPDF({ format: "letter" });

	/* Primera Hoja */
	doc.addImage(formularioPatentePrimero, "JPEG", 0, 0, 210, 280);
	doc.setFont("arial");
	doc.setFontSize(14);
	doc.text(razon, 30, 59);
	doc.text(rutEmpresa, 163, 59);
	doc.text("Ahumada 254, piso 8, oficina", 49, 67);
	doc.text("806", 160, 67);
	doc.text("Santiago", 49, 75);
	doc.text("Ahumada", 30, 83);
	doc.text("254", 170, 83);
	doc.text("Of. 806", 45, 90);
	doc.text(
		actividadEspecifica.charAt(0).toUpperCase() +
			actividadEspecifica.slice(1).toLowerCase(),
		30,
		98
	);
	doc.line(18, 128, 23, 134);
	doc.line(18, 134, 23, 128);
	doc.text(fecha.getDate().toString(), 45, 216);
	doc.text(mes, 75, 216);
	doc.text(year.toString(), 116, 217);

	/* Segunda Hoja */
	doc.addPage("letter");
	doc.addImage(formularioPatenteSegundo, "JPEG", 0, 0, 210, 280);
	doc.setFont("arial");
	doc.setFontSize(14);
	doc.line(12, 32, 16, 38);
	doc.line(12, 38, 16, 32);
	doc.text("Ahumada", 30, 74);
	doc.text("254", 117, 82);
	doc.line(12, 93, 18, 100);
	doc.line(12, 100, 18, 93);
	doc.text("806", 45, 97);
	doc.line(49, 108, 53, 112);
	doc.line(49, 112, 53, 108);
	doc.text("103-76", 117, 110);

	/* Codigo Actividad y Giro Especifico */
	doc.setFontSize(12);
	doc.text(codigo1, 25, 131);
	doc.text(
		giro1.charAt(0).toUpperCase() + giro1.slice(1).toLowerCase(),
		55,
		131
	);
	doc.text(codigo2, 25, 137);
	doc.text(
		giro2.charAt(0).toUpperCase() + giro2.slice(1).toLowerCase(),
		55,
		137
	);
	doc.text(codigo3, 25, 144);
	doc.text(
		giro3.charAt(0).toUpperCase() + giro3.slice(1).toLowerCase(),
		55,
		144
	);
	doc.text(
		actividadEspecifica.charAt(0).toUpperCase() +
			actividadEspecifica.slice(1).toLowerCase(),
		50,
		149
	); /* Actividad Especifica */
	doc.line(99, 162, 104, 166);
	doc.line(99, 166, 104, 162);
	doc.line(189, 162, 194, 166);
	doc.line(189, 166, 194, 162);

	doc.setFontSize(14);
	doc.text(razon, 20, 180);
	doc.text(rutEmpresa, 15, 189);
	doc.text("+56963006455", 100, 189);
	doc.text("Ahumada", 20, 198);
	doc.text("254", 87, 198);
	doc.text("Oficina 806", 112, 198);
	doc.text("Santiago", 175, 198);
	doc.text("Rodrigo", 66, 208);
	doc.text("denegocios.cl", 85, 208);

	doc.line(80, 219, 85, 224);
	doc.line(80, 224, 85, 219);

	doc.text(nombre, 15, 239);
	doc.text(rut, 15, 246);
	doc.text("Rodrigo", 121, 246);
	doc.text("denegocios.cl", 142, 246);
	doc.text("Ahumada", 20, 254);
	doc.text("254", 95, 254);
	doc.text("903", 120, 254);
	doc.text("Santiago", 175, 254);
	doc.text("+56963006455", 100, 263);

	/* Tercera Hoja */
	doc.addPage("letter");
	doc.addImage(formularioPatenteTercero, "JPEG", 0, 0, 210, 280);
	doc.setFont("arial");
	doc.setFontSize(10);
	doc.text(numeroTrabajadoresInmueble, 160, 232);
	doc.text(numeroTrabajadoresPais, 160, 236);
	doc.text(capitalNegocio, 160, 240);
	doc.save(`Documentos Patente para ${razon}.pdf`);
};
