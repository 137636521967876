import React, { useContext, Fragment, useState, useEffect } from "react";
import { Context } from "../store/AppContext";
import Head from "../component/Head";
import "../../styles/App.css";
import Recuperar from "../component/Login/Recuperar";
import Bienvenida from "../component/Login/Bienvenida";
import FormularioLogin from "../component/Login/FormularioLogin";
import fondoLogin5 from "../../img/fondoLogin5.jpg";
import fondoLogin4 from "../../img/fondoLogin4.jpg";
import fondoLogin3 from "../../img/fondoLogin3.jpg";
import fondoLogin2 from "../../img/fondoLogin2.jpg";
import fondoLogin from "../../img/fondoLogin.jpg";

export const Login = () => {
	const { store, actions } = useContext(Context);
	const titulosHead = [
		"Bienvenido a Administracion DeNegocios.cl",
		"Por favor, ingresa a tu sesión.",
	];
	const titulosHead2 = ["Bienvenido a Administracion DeNegocios.cl", ""];
	const [bienvenida, setBienvenida] = useState(false);
	const [recuperarClave, setRecuperarClave] = useState(false);

	useEffect(() => {
		if (
			JSON.parse(localStorage.getItem("usuarioActual")) != null ||
			(store.usuarioActual != null && store.token != null)
		) {
			setBienvenida(true);
		} /* 
		setTimeout(() => {
			if (store.usuarioActual != null && store.token != null) {
				setBienvenida(true);
			}
		}, 10); */
	}, []);

	/* Imagenes para Fondos */
	const RandomImagen = () => {
		let imagenRandom = Math.round(Math.random() * 4);
		switch (imagenRandom) {
			case 0:
				return fondoLogin;
				break;
			case 1:
				return fondoLogin2;
				break;
			case 2:
				return fondoLogin3;
				break;
			case 3:
				return fondoLogin4;
				break;
			case 4:
				return fondoLogin5;
				break;
			default:
				break;
		}
	};

	/* Se han agregado algunos css inline para el tema de las imagenes */

	return (
		<Fragment>
			{bienvenida ? (
				<Head contenido={titulosHead2} />
			) : (
				<Head contenido={titulosHead} />
			)}
			{bienvenida ? <Bienvenida /> : null}
			{recuperarClave ? (
				<Recuperar setRecuperarClave={setRecuperarClave} />
			) : null}
			{!bienvenida && !recuperarClave ? (
				<div className=''>
					<div
						style={{
							backgroundImage: `url(${RandomImagen()})`,
							marginTop: "-20px",
							paddingBottom: "200px",
						}}
					>
						<FormularioLogin
							setBienvenida={setBienvenida}
							setRecuperarClave={setRecuperarClave}
						/>
					</div>
				</div>
			) : null}
		</Fragment>
	);
};
