import React, { useEffect, useState } from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const Estadisticas0 = ({
	ventasTotales,
	ventasProyectadas,
	facturasPendientes,
	kpi,
	kpiProyectado,
	comisionBase,
	sueldoBase,
	ventasMesPrevio,
	sueldoLiquido,
}) => {
	const [divAbierto, setDivAbierto] = useState("true");
	const percentage = Math.round((ventasTotales / ventasMesPrevio) * 100);

	function round(value, precision) {
		var multiplier = Math.pow(10, precision || 0);
		return Math.round(value * multiplier) / multiplier;
	}

	useEffect(() => {
		if (localStorage.getItem("divAbierto") != null) {
			if (localStorage.getItem("divAbierto") === "true") {
				setDivAbierto("true");
			} else if (localStorage.getItem("divAbierto") === "false") {
				setDivAbierto("false");
			}
		}
	}, []);

	const HandlerCerrar = () => {
		const abrir = () => {
			setDivAbierto("true");
			localStorage.setItem("divAbierto", "true");
		};
		const cerrar = () => {
			setDivAbierto("false");
			localStorage.setItem("divAbierto", "false");
		};
		divAbierto === "true" ? cerrar() : abrir();
	};

	const DivCerrado = () => {
		return (
			<div style={{ textAlign: "center" }}>
				<a onClick={(e) => HandlerCerrar(e)}>Desplegar Estadisticas &#43;</a>
			</div>
		);
	};

	const DivAbierto = () => {
		return (
			<div className='callout' data-closable>
				<div className='row'>
					<button
						className='close-button'
						aria-label='Dismiss alert'
						type='button'
						data-close
						onClick={(e) => HandlerCerrar(e)}
					>
						<span aria-hidden='true'>&#8722;</span>
					</button>
					&nbsp;
					<div className='columns small-12'>
						<div className='columns small-3'>
							<div className='callout'>
								<p>
									<strong>Como vas este mes?</strong>
								</p>
								<CircularProgressbar
									value={percentage}
									text={`${percentage}%`}
								/>
								<br />
								<p>
									{percentage >= 100 &&
										`Tus ventas este mes son mayores al mes pasado en un ${
											percentage - 100
										}%. El mes pasado solo vendiste ${new Intl.NumberFormat(
											"es-CL",
											{
												currency: "CLP",
												style: "currency",
											}
										).format(ventasMesPrevio)}`}
									{percentage < 100 &&
										`Tus ventas este mes son menores al mes pasado en un ${
											100 - percentage
										}%. El mes pasado vendiste ${new Intl.NumberFormat(
											"es-CL",
											{
												currency: "CLP",
												style: "currency",
											}
										).format(ventasMesPrevio)}`}
								</p>
							</div>
						</div>
						<div className='columns small-5'>
							<div className='callout'>
								<strong>KPI</strong>
								<p>{round(kpi, 2)}</p>
							</div>
							<div className='callout'>
								<strong>Ventas Acumuladas Mes</strong>
								<p>
									{new Intl.NumberFormat("es-CL", {
										currency: "CLP",
										style: "currency",
									}).format(ventasTotales)}
								</p>
							</div>
							<div className='callout'>
								<strong>KPI Proyectado</strong>
								<p>{round(kpiProyectado, 2)}</p>
							</div>
							<div className='callout'>
								<strong>Ventas Proyectadas</strong>
								<p>
									{new Intl.NumberFormat("es-CL", {
										currency: "CLP",
										style: "currency",
									}).format(ventasProyectadas)}
								</p>
							</div>
						</div>
						<div className='columns small-4'>
							<div className='callout'>
								<strong>Sueldo Base</strong>
								<p>
									{new Intl.NumberFormat("es-CL", {
										currency: "CLP",
										style: "currency",
									}).format(sueldoBase)}
								</p>
							</div>
							<div className='callout'>
								<strong>Bono de Producción</strong>
								<p>
									{new Intl.NumberFormat("es-CL", {
										currency: "CLP",
										style: "currency",
									}).format(comisionBase * 400)}
								</p>
							</div>
							<div className='callout'>
								<strong>Sueldo líquido</strong>
								<p>
									{new Intl.NumberFormat("es-CL", {
										currency: "CLP",
										style: "currency",
									}).format(sueldoLiquido)}
								</p>
							</div>
							<div className='callout'>
								<strong>Facturas Pendientes</strong>
								<p>{facturasPendientes}</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	};

	return (
		<div className='columns small-12'>
			{divAbierto === "true" ? <DivAbierto /> : <DivCerrado />}
		</div>
	);
};

export default Estadisticas0;
