import React, { useContext, useState } from "react";
import { Context } from "../../store/AppContext";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

const Bienvenida = () => {
	const { store, actions } = useContext(Context);
	const [click, setClick] = useState(false);
	const [celebracion, setCelebracion] = useState({
		nombre: null,
		fecha: null,
	});
	const [mesFeriado, setMesFeriado] = useState(new Date().getMonth());

	/* Objeto de Fechas de Cumpleaños */
	const fechas = {
		erick: new Date(2020, 0, 7),
		gislen: new Date(2020, 0, 22),
		victor: new Date(2020, 1, 15),
		alejandra: new Date(2020, 6, 14),
		lucas: new Date(2020, 4, 24),
		maria: new Date(2020, 5, 23),
		rodrigo: new Date(2020, 7, 2),
		emilio: new Date(2020, 7, 4),
		lorena: new Date(2020, 7, 28),
		macarena: new Date(2020, 9, 5),
		reynaldo: new Date(2020, 1, 15),
		ricardo: new Date(2020, 10, 12),
		javiera: new Date(2020, 11, 14),
		italia: new Date(2020, 1, 3),
		aroon: new Date(2020, 0, 2),
		constanza: new Date(2020, 2, 8),
		rosa: new Date(2020, 5, 9),
		colomba: new Date(2020, 4, 3),
	};

	/* Lista de Feriados debe ser actualizada Anualmente */
	const feriados = [
		{ feriado: "Año Nuevo", date: new Date(2024, 0, 1) },
		{ feriado: "Viernes Santo", date: new Date(2024, 2, 29) },
		{ feriado: "Sábado Santo", date: new Date(2024, 2, 30) },
		{ feriado: "Día Nacional del Trabajo", date: new Date(2024, 4, 1) },
		{ feriado: "Día de las Glorias Navales", date: new Date(2024, 4, 21) },
		{
			feriado: "Elecciones Primarias Alcaldes y Gobernadores",
			date: new Date(2024, 5, 9),
		},
		{
			feriado: "Día Nacional de los Pueblos Indígenas",
			date: new Date(2024, 5, 21),
		},
		{ feriado: "San Pedro y San Pablo", date: new Date(2024, 5, 29) },
		{ feriado: "Día de la Virgen del Carmen", date: new Date(2024, 6, 16) },
		{ feriado: "Asunción de la Virgen", date: new Date(2024, 7, 15) },
		{ feriado: "Independencia Nacional", date: new Date(2024, 8, 18) },
		{ feriado: "Día de las Glorias del Ejército", date: new Date(2024, 8, 19) },
		{ feriado: "Feriado Adicional", date: new Date(2024, 8, 20) },
		{ feriado: "Encuentro de Dos Mundos", date: new Date(2024, 9, 12) },
		{
			feriado:
				"Elecciones Municipales, Consejeros Regionales y Gobernadores Regionales",
			date: new Date(2024, 9, 27),
		},
		{
			feriado: "Día de las Iglesias Evangélicas y Protestantes",
			date: new Date(2024, 9, 31),
		},
		{ feriado: "Día de Todos los Santos", date: new Date(2024, 10, 1) },
		{
			feriado: "Segunda Vuelta Elección Gobernadores Regionales",
			date: new Date(2024, 10, 24),
		},
		{ feriado: "Inmaculada Concepción", date: new Date(2024, 11, 8) },
		{ feriado: "Navidad", date: new Date(2024, 11, 25) },
	];

	const ListaFeriados = (objeto, i) => {
		if (objeto.date.getMonth() === mesFeriado) {
			return (
				<li key={i}>
					Día {objeto.date.getDate()}: {objeto.feriado}
				</li>
			);
		}
	};

	const HandlerClickCalendario = (value, event) => {
		if (event) {
			setCelebracion({
				nombre: null,
				fecha: null,
			});
			setClick(false);
		}
		if (
			Object.values(fechas).some(
				(fecha) =>
					fecha.getMonth() === value.getMonth() &&
					fecha.getDate() === value.getDate()
			)
		) {
			Object.keys(fechas).forEach((key) => {
				if (
					fechas[key].getMonth() === value.getMonth() &&
					fechas[key].getDate() === value.getDate()
				) {
					setCelebracion({
						nombre: key.toUpperCase(),
						fecha: fechas[key],
					});
					setClick(true);
				}
			});
		}
	};

	return (
		<div className='row'>
			<div className='column'>
				<h2>Hola {store.usuarioActual.nombre}, Bienvenid@.</h2>
				{Object.values(fechas).some(
					(fecha) =>
						fecha.getMonth() === new Date().getMonth() &&
						fecha.getDate() === new Date().getDate()
				) && (
					<div className='suph1'>
						<h1 className='h1'>🎂 Hoy hay Cumpleaños! 🎉</h1>
					</div>
				)}

				<hr />
				<br />
				<div className='row'>
					<div className='columns small-5'>
						<h5>Calendario</h5>
						<Calendar
							locale='es-cl'
							onClickDay={(value, event) =>
								HandlerClickCalendario(value, event)
							}
							onActiveStartDateChange={({
								action,
								activeStartDate,
								value,
								view,
							}) => setMesFeriado(activeStartDate.getMonth())}
							showNeighboringMonth={false}
							tileClassName={({ date, view }) =>
								Object.values(fechas).some(
									(fecha) =>
										fecha.getDate() === date.getDate() &&
										fecha.getMonth() === date.getMonth()
								)
									? "cumple"
									: feriados.some(
											(fecha) =>
												fecha.date.getDate() === date.getDate() &&
												fecha.date.getMonth() === date.getMonth()
									  )
									? "feriado"
									: null
							}
						/>
						<br />
					</div>
					<div className='columns small-1'>&nbsp;</div>
					<div className='columns small-6'>
						<h5>Fechas Importantes</h5>
						<ul>
							<li>
								<h6>Dia 13: Fecha Máxima Pago Previred</h6>
							</li>
							<li>
								<h6>Dia 20: Fecha Máxima Pago Impuestos al SII</h6>
							</li>
						</ul>
						<br />
						<h5>Feriados</h5>
						<ul>
							{feriados != null &&
								feriados.map((objeto, i) => ListaFeriados(objeto, i))}
						</ul>
						<br />
						{click ? (
							<div>
								<h5>Cumpleaño</h5>
								<ul>
									<li>
										<h6>
											Dia {celebracion.fecha.getDate()}: Feliz Cumpleaños{" "}
											{celebracion.nombre} ❤️
										</h6>
										<p></p>
									</li>
								</ul>
							</div>
						) : null}
					</div>
				</div>
				<div className='row'>
					<div className='cell small-6'>
						<h5>Links de Interés</h5>
						<ul className='callout'>
							<li>
								<a
									href='https://denegocios.cl/'
									target='_blank'
									rel='noopener noreferrer'
								>
									Página de DeNegocios.cl
								</a>
							</li>
							<li>
								<a
									href='https://zeus.sii.cl/cvc/stc/stc.html'
									target='_blank'
									rel='noopener noreferrer'
								>
									Informacion de Terceros (SII)
								</a>
							</li>
							<li>
								<a
									href='https://www4.sii.cl/busquedarolesinternetui/#!/busquedaroles'
									target='_blank'
									rel='noopener noreferrer'
								>
									Buscar Rol de una Propiedad
								</a>
							</li>
							<li>
								<a
									href='https://tramites.munistgo.cl/pagopatente/'
									target='_blank'
									rel='noopener noreferrer'
								>
									Consultar Pantente Municipal Santiago
								</a>
							</li>
							<li>
								<a
									href='https://www.registrocivil.cl/principal/servicios-en-linea/consulta-vigencia-documento-1'
									target='_blank'
									rel='noopener noreferrer'
								>
									Revisar Vigencia Cédula Identidad
								</a>
							</li>
							<li>
								<a
									href='https://www.sii.cl/ayudas/ayudas_por_servicios/1956-codigos-1959.html'
									target='_blank'
									rel='noopener noreferrer'
								>
									Códigos/Giros del SII
								</a>
							</li>
							<li>
								<a
									href='https://www.registrodeempresasysociedades.cl/ObtenerCertificadosHome.aspx'
									target='_blank'
									rel='noopener noreferrer'
								>
									Obtener Documentos Empresa en 1 Día
								</a>
							</li>
							<li>
								<a
									href='https://conservador.cl/portal/indice_comercio'
									target='_blank'
									rel='noopener noreferrer'
								>
									Registro de Comercio de Santiago (Tradicionales)
								</a>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Bienvenida;
