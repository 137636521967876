import React, { useEffect, useContext, useState } from "react";
import { Context } from "../../../store/AppContext";

const FormularioModificarIngresoDatos = ({
	idSeleccionado,
	setMesVentaSeleccionado,
	setModificarIngresoDatos,
}) => {
	const { store, actions } = useContext(Context);
	const [datos, setDatos] = useState({
		yearIngreso: null,
		mesIngreso: null,
		ventas: null,
		ventasTotales: null,
		ventasTotalesPasado: null,
		kpi: null,
		gastoAds: null,
		totalPosibles: null,
		adsPorVendedor: null,
		posiblesPorVendedor: null,
		facturasPendientes: null,
		sueldoBase: null,
		comisionBase: null,
		sueldoLiquido: null,
	});

	useEffect(() => {
		actions.getVentasUsuarioPorID(idSeleccionado);
	}, []);

	const HandlerCompletarDatos = (event) => {
		setDatos({ ...datos, [event.target.name]: event.target.value });
	};

	const HandlerModificarInformacion = () => {
		actions.setSpinner();
		actions.editarVentaEspecifica(
			idSeleccionado,
			datos.mesIngreso,
			datos.yearIngreso,
			datos.ventas,
			datos.ventasTotales,
			datos.ventasTotalesPasado,
			datos.kpi,
			datos.gastoAds,
			datos.totalPosibles,
			datos.adsPorVendedor,
			datos.posiblesPorVendedor,
			datos.facturasPendientes,
			datos.sueldoBase,
			datos.comisionBase,
			datos.sueldoLiquido
		);
		setTimeout(() => {
			actions.setSpinner();
			setModificarIngresoDatos(false);
			/* setMesVentaSeleccionado(false); */
		}, 500);
	};

	const HandlerCerrar = () => {
		setMesVentaSeleccionado(false);
	};

	return (
		<div className='callout' data-closable>
			<button
				className='close-button'
				aria-label='Dismiss alert'
				type='button'
				data-close
				onClick={(e) => HandlerCerrar(e)}
			>
				<span aria-hidden='true'>×</span>
			</button>
			<br />
			{store.ventasUsuarioPorId != null && (
				<>
					<div className='grid-x'>
						<div className='cell'>
							<h3>
								Modificación de Información para{" "}
								{store.ventasUsuarioPorId[0].nombre} mes de{" "}
								{store.ventasUsuarioPorId[0].mesIngreso} del{" "}
								{store.ventasUsuarioPorId[0].yearIngreso}
							</h3>
							<small>
								Con esta opción se puede modificar individualmente la
								información para este vendedor.
							</small>
						</div>
					</div>
					<br />
					Modifica los Datos que desees.
					<form
						className='log-in-form'
						style={{
							backgroundColor: "rgba(46, 138, 199, 0.25)",
							padding: "20px",
						}}
					>
						<div className='row'>
							<div className='columns small-12'>
								{/* Primer Bloque */}
								<div className='columns small-4'>
									<label style={{ color: "grey", textShadow: "1px 1px black" }}>
										Año de Ingreso: {store.ventasUsuarioPorId[0].yearIngreso}
										<input
											type='number'
											placeholder='2035'
											name='yearIngreso'
											onChange={(e) => HandlerCompletarDatos(e)}
										/>
									</label>
									<label style={{ color: "grey", textShadow: "1px 1px black" }}>
										Mes de Ingreso: {store.ventasUsuarioPorId[0].mesIngreso}
										<input
											type='text'
											placeholder='Enero'
											name='mesIngreso'
											onChange={(e) => HandlerCompletarDatos(e)}
										/>
									</label>
								</div>
								{/* Segundo Bloque */}
								<div className='columns small-4'>
									<label style={{ color: "grey", textShadow: "1px 1px black" }}>
										Gasto Ads Mes Pasado: {store.ventasUsuarioPorId[0].gastoAds}
										<input
											type='number'
											placeholder='1000000'
											name='gastoAds'
											onChange={(e) => HandlerCompletarDatos(e)}
										/>
									</label>
									<label style={{ color: "grey", textShadow: "1px 1px black" }}>
										Gasto Ads Mes Pasado Individual:{" "}
										{store.ventasUsuarioPorId[0].adsPorVendedor}
										<input
											type='number'
											placeholder='1000000'
											name='adsPorVendedor'
											onChange={(e) => HandlerCompletarDatos(e)}
										/>
									</label>
									<label style={{ color: "grey", textShadow: "1px 1px black" }}>
										Ventas Mes Pasado Individual:{" "}
										{store.ventasUsuarioPorId[0].ventasTotalesPasado}
										<input
											type='number'
											placeholder='1000000'
											name='ventasTotalesPasado'
											onChange={(e) => HandlerCompletarDatos(e)}
										/>
									</label>
								</div>
								{/* Tercer Bloque */}
								<div className='columns small-4'>
									<label style={{ color: "grey", textShadow: "1px 1px black" }}>
										Total Posibles Mes Pasado:{" "}
										{store.ventasUsuarioPorId[0].totalPosibles}
										<input
											type='number'
											placeholder='500'
											name='totalPosibles'
											onChange={(e) => HandlerCompletarDatos(e)}
										/>
									</label>
									<label style={{ color: "grey", textShadow: "1px 1px black" }}>
										Total Posibles Mes Pasado Individual:{" "}
										{store.ventasUsuarioPorId[0].posiblesPorVendedor}
										<input
											type='number'
											placeholder='500'
											name='posiblesPorVendedor'
											onChange={(e) => HandlerCompletarDatos(e)}
										/>
									</label>
								</div>
								{/* Cuarto Bloque */}
								<div className='columns small-4'>
									<label style={{ color: "grey", textShadow: "1px 1px black" }}>
										Sueldo Base para este Mes:{" "}
										{store.ventasUsuarioPorId[0].sueldoBase}
										<input
											type='number'
											placeholder='400000'
											name='sueldoBase'
											onChange={(e) => HandlerCompletarDatos(e)}
										/>
									</label>
								</div>
							</div>
						</div>
					</form>
				</>
			)}
			<br />
			<button
				className='button'
				onClick={(e) => HandlerModificarInformacion(e)}
			>
				Modificar Información
			</button>
		</div>
	);
};

export default FormularioModificarIngresoDatos;
