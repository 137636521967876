import React, { Fragment, useState, useEffect, useContext } from "react";
import Head from "../../Head";
import NuevoClienteConta from "./NuevoCliente/NuevoClienteConta";
import BotonesOpciones from "./BotonesOpciones";
/* import VistaDtUsuarioCobranza from "./PantallaAntigua/VistaDtUsuarioCobranza"; */
import VistaBuscadorEspecificoConta from "./BuscadorEspecificoConta/VistaBuscadorEspecificoConta";
import "../../../../styles/Modal.css";
import BusquedaCompleta from "./BusquedaCompleta/BusquedaCompleta";
import ClienteSeleccionado from "../ClienteSeleccionado";
import VistaEstadisticas from "../Estadisticas/VistaEstadisticas";
import TopScroll from "../../TopScroll";
import { Context } from "../../../store/AppContext";

const VistaInicialContaCobranza = () => {
	const { store, actions } = useContext(Context);
	const titulosHead = [
		"Bienvenido al Servicio de Contabilidad",
		"Por favor, selecciona que deseas realizar:",
	];
	const [nuevoCliente, setNuevoCliente] = useState(false);
	const [busquedaClientes, setBusquedaClientes] = useState(false);
	const [busquedaCompleta, setBusquedaCompleta] = useState(false);
	const [tipoFiltro, setTipoFiltro] = useState(null);
	const [animar, setAnimar] = useState(false);
	const [animarModal, setAnimarModal] = useState(false);
	const [clienteSeleccionado, setClienteSeleccionado] = useState(false);
	const [clienteContaCliqueado, setClienteContaCliqueado] = useState(null);
	const [estadisticas, setEstadisticas] = useState(false);

	const HandlerNuevoCliente = (event) => {
		setNuevoCliente(true);
		setTimeout(() => {
			setAnimarModal(true);
		}, 500);
	};

	const HandlerBusquedaClientes = () => {
		setBusquedaClientes(true);
	};

	const HandlerBusquedaCompleta = (event) => {
		actions.setSpinner(true);
		setTimeout(() => {
			setTipoFiltro(event.target.id);
			setBusquedaCompleta(true);
		}, 500);
	};

	const HandlerEstadisticas = () => {
		setEstadisticas(true);
	};

	//Animacion
	useEffect(() => {
		setAnimar(true);
	}, []);

	return (
		<Fragment>
			<Head contenido={titulosHead} />
			<div className='row'>
				<div className='column'>
					{!nuevoCliente && !clienteSeleccionado && <TopScroll />}
					{nuevoCliente && (
						<>
							<NuevoClienteConta
								setNuevoCliente={setNuevoCliente}
								animarModal={animarModal}
								setAnimarModal={setAnimarModal}
								setClienteContaCliqueado={setClienteContaCliqueado}
								setClienteSeleccionado={setClienteSeleccionado}
							/>
						</>
					)}
					{clienteSeleccionado && (
						<ClienteSeleccionado
							setClienteSeleccionado={setClienteSeleccionado}
							clienteContaCliqueado={clienteContaCliqueado}
						/>
					)}
					{busquedaClientes && (
						<VistaBuscadorEspecificoConta
							setBusquedaClientes={setBusquedaClientes}
						/>
					)}
					{busquedaCompleta && (
						<BusquedaCompleta
							setBusquedaCompleta={setBusquedaCompleta}
							tipoFiltro={tipoFiltro}
						/>
					)}
					{estadisticas && (
						<VistaEstadisticas setEstadisticas={setEstadisticas} />
					)}
					{!busquedaClientes &&
						!busquedaCompleta &&
						!clienteSeleccionado &&
						!estadisticas && (
							<BotonesOpciones
								HandlerNuevoCliente={HandlerNuevoCliente}
								animar={animar}
								HandlerBusquedaClientes={HandlerBusquedaClientes}
								HandlerBusquedaCompleta={HandlerBusquedaCompleta}
								HandlerEstadisticas={HandlerEstadisticas}
							/>
						)}
					{nuevoCliente && (
						<>
							<br />
							<br />
							<br />
							<br />
							<br />
							<br />
							<br />
							<br />
							<br />
							<br />
							<br />
							<br />
							<br />
							<br />
							<br />
							<br />
							<br />
							<br />
							<br />
							<br />
							<br />
							<br />
							<br />
							<br />
							<br />
							<br />
							<br />
							<br />
							<br />
							<br />
							<br />
						</>
					)}
				</div>
			</div>
		</Fragment>
	);
};

export default VistaInicialContaCobranza;
