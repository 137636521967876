import React from "react";

const VentasVendedores = ({
	estadisticasAmpliadas,
	setEstadisticasAmpliadas,
}) => {
	const HandlerCerrar = () => {
		setEstadisticasAmpliadas(false);
	};

	return (
		<div className='row'>
			<div className='callout' data-closable>
				<button
					className='close-button no-print'
					aria-label='Dismiss alert'
					type='button'
					data-close
					onClick={(e) => HandlerCerrar(e)}
				>
					<span aria-hidden='true'>×</span>
				</button>
				<br className='no-print' />
				<div className='grid-x'>
					<div className='cell small-6'>
						<h3>Estadisticas Ampliadas</h3>
						<h1>En Desarrollo</h1>
					</div>
				</div>
			</div>
		</div>
	);
};

export default VentasVendedores;
