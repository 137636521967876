import React, { useContext, useState } from "react";
import { Context } from "../../../../store/AppContext.js";
import "../../../../../styles/TablaClientes.css";
import PanelInformativoConta from "./PanelInformativoConta.js";

const ListaClientesContaCompleta = ({
	setClienteSeleccionado,
	setClienteContaCliqueado,
	colores,
	tipoFiltro,
}) => {
	const { store, actions } = useContext(Context);
	const array = new Map();
	const [panel, setPanel] = useState(false);

	const HandlerSaldo = () => {
		setPanel(true);

		setTimeout(() => {
			setPanel(false);
		}, 10000);
	};

	const HandlerClick = (object, event) => {
		if (event.target.className != "telefono") {
			setClienteContaCliqueado(object);
			setClienteSeleccionado(true);
		}
	};

	//Funciones para el Saldo
	const CalculoSaldo = (objeto, meses) => {
		let montoPagado = 0;
		let montoCobrado = 0;
		if (objeto.pagosContabilidadID != undefined) {
			for (let index = 0; index < objeto.pagosContabilidadID.length; index++) {
				montoPagado =
					montoPagado + parseInt(objeto.pagosContabilidadID[index].montoPagado);
				montoCobrado =
					montoCobrado +
					parseInt(objeto.pagosContabilidadID[index].montoCobrado);
			}
		}
		if (tipoFiltro === "Todo") {
			array.set(objeto.id, montoCobrado - montoPagado);
		}

		if (tipoFiltro === "Vigente") {
			array.set(objeto.id, montoCobrado - montoPagado);
		}

		if (tipoFiltro === "No Vigente") {
			array.set(objeto.id, montoCobrado - montoPagado + 1000);
		}

		if (tipoFiltro === "Con DICOM") {
			array.set(objeto.id, montoCobrado - montoPagado + 1000);
		}

		if (tipoFiltro === "Con Riesgo") {
			array.set(objeto.id, montoCobrado - montoPagado + 1000);
		}
		return montoCobrado - montoPagado;
	};

	const Filtro = (objeto) => {
		// Esta función permite Filtrar el Listado de Clientes, por tipo Filtro.
		if (tipoFiltro === "Todo") {
			return objeto;
		} else if (tipoFiltro === "Vigente") {
			if (objeto.vigente === "Si") {
				return objeto;
			}
		} else if (tipoFiltro === "No Vigente") {
			if (objeto.vigente === "No") {
				return objeto;
			}
		} else if (tipoFiltro === "Con DICOM") {
			if (objeto.dicom === "Si") {
				return objeto;
			}
		} else if (tipoFiltro === "Con Riesgo") {
			if (objeto.repetido === "Si") {
				return objeto;
			}
		}
	};

	//La siguiente funcion despliega la lista de Clientes con sus respectivas Columnas.
	const ListaDesplegarClientes = (objeto, i) => {
		return (
			<tr
				className='trVistaAmpliada'
				key={i}
				onClick={(e) => HandlerClick(objeto, e)}
			>
				<td>{objeto.id}</td>
				<td>{objeto.razon}</td>
				<td>{objeto.rut}</td>
				<td>
					{objeto.correo}
					{objeto.correoSecundario === "" ? null : (
						<span>, {objeto.correoSecundario}</span>
					)}
					{objeto.correoTerciario === "" ? null : (
						<span>, {objeto.correoTerciario}</span>
					)}
				</td>
				<td className='telefono' style={{ cursor: "copy" }}>
					{objeto.fono}
				</td>
				<td>{objeto.vigente}</td>
				<td>${CalculoSaldo(objeto, false)}</td>
				<td>
					{objeto.dicom == "Si"
						? objeto.repetido == "Si"
							? "Dicom"
							: "Riesgo"
						: objeto.repetido == "Si"
						? "Dicom"
						: "Normal"}
				</td>
			</tr>
		);
	};

	return (
		<div className={store.witch ? "" : "grid-x grid-margin-x"}>
			<button className='clear button alert' onClick={(e) => HandlerSaldo(e)}>
				Ver Algunos Datos
			</button>
			{panel && (
				<PanelInformativoConta
					array={array}
					setPanel={setPanel}
					tipoFiltro={tipoFiltro}
				/>
			)}
			{sessionStorage.getItem("listadoClientesConta") != null ? (
				<table
					className={`table hover ${store.witch ? "tablaClientes" : ""}`}
					id='tblData'
				>
					<thead>
						<tr>
							<th className='id' scope='col'>
								Id
							</th>
							<th scope='col'>Razon Social</th>
							<th className='rut' scope='col'>
								Rut
							</th>
							<th scope='col'>Correo</th>
							<th className='telefono' scope='col'>
								Teléfono
							</th>
							<th scope='col'>Vigente</th>
							<th className='saldo' scope='col'>
								Saldo
							</th>
							<th className='situacion' scope='col'>
								Situacion
							</th>
						</tr>
					</thead>
					<tbody>
						{sessionStorage.getItem("listadoClientesConta") != null ? (
							JSON.parse(sessionStorage.getItem("listadoClientesConta"))
								.filter((objeto) => Filtro(objeto))
								.map((objeto, i) => ListaDesplegarClientes(objeto, i))
						) : (
							<td colSpan='9' style={{ height: "100px", padding: "20px" }}>
								<h2 className='text-center'> - no hay datos -</h2>
							</td>
						)}
					</tbody>
				</table>
			) : null}
		</div>
	);
};

export default ListaClientesContaCompleta;
