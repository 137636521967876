import React, { Fragment, useContext, useEffect } from "react";
import { Context } from "../store/AppContext";
import { useNavigate } from "react-router-dom";
import VistaInicialContaCobranza from "../component/Contabilidad/UsuarioCobranza/VistaInicialContaCobranza";
import VistaContaUsuarioNormal from "../component/Contabilidad/UsuarioNormal/VistaContaUsuarioNormal";
import "../../styles/PagosDt.css";

const Contabilidad = () => {
	const { store, actions } = useContext(Context);
	const history = useNavigate();

	useEffect(() => {
		setTimeout(() => {
			if (store.usuarioActual == null && store.token == null) {
				history("/");
			}
		}, 200);
	});

	return (
		<Fragment>
			{store.usuarioActual != null ? (
				store.usuarioActual.tipo === "Administrador" ||
				store.usuarioActual.tipo === "Cobranza" ||
				store.usuarioActual.tipo === "Contabilidad" ||
				store.usuarioActual.tipo === "Super Administrador" ? (
					<VistaInicialContaCobranza />
				) : (
					<VistaContaUsuarioNormal />
				)
			) : null}
		</Fragment>
	);
};

export default Contabilidad;
