import React, { useContext, useState, useEffect } from "react";
import { Context } from "../../store/AppContext";
import "../../../styles/App.css";

const FormularioLogin = ({ setBienvenida, setRecuperarClave }) => {
	const { store, actions } = useContext(Context);
	const [correo, setCorreo] = useState(null);
	const [clave, setClave] = useState(null);

	const HandlerRecuperarClave = (event) => {
		setRecuperarClave(true);
	};

	useEffect(() => {
		if (store.usuarioActual != null) {
			setBienvenida(true);
		}
	});

	const HandlerLogin = (event) => {
		event.preventDefault();
		if (correo === "" || correo === null) {
			if (clave === "" || clave === null) {
				alert("Por favor, completa los Datos");
				return;
			}
			alert("Revisa tu Correo");
		}
		if (clave === "" || clave === null) {
			alert("Revisa tu clave");
			return;
		}
		if (correo && clave) {
			if (clave.trim() === "" || correo.trim() === "") {
				alert("Revisa tu correo y clave, por favor.");
				return;
			} else {
				actions.loginUsuario(correo, clave);
			}
		}
	};

	/* Se han agregado algunos css inline para el tema de las imagenes */

	return (
		<div className='grid-x grid-margin-x'>
			<div className='cell small-4 large-offset-4'>
				<br />
				<br />
				<br />
				<br />
				<br />
				<br />
				<form
					className='log-in-form'
					style={{
						backgroundColor: "rgba(46, 138, 199, 0.25)",
						padding: "20px",
					}}
				>
					<h4
						className='text-center'
						style={{ color: "grey", textShadow: "1px 1px black" }}
					>
						Ingresa con tu Correo de DeNegocios.cl
					</h4>
					<div className='text-left'>
						<label style={{ color: "grey", textShadow: "1px 1px black" }}>
							Email
							<input
								type='email'
								placeholder='correo@denegocios.cl'
								onChange={(event) => setCorreo(event.target.value)}
							/>
						</label>
						<label style={{ color: "grey", textShadow: "1px 1px black" }}>
							Clave
							<input
								type='password'
								placeholder='Clave'
								onChange={(event) => setClave(event.target.value)}
							/>
						</label>
						<a className='button expanded' onClick={(e) => HandlerLogin(e)}>
							Ingresar
						</a>
					</div>
					<p className='text-center'>
						<a
							className='clear button secondary'
							onClick={(e) => HandlerRecuperarClave(e)}
							style={{ color: "white" }}
						>
							Olvidaste tu Clave?
						</a>
					</p>
				</form>
			</div>
		</div>
	);
};

export default FormularioLogin;
