import { useContext, useState } from "react";
import { Context } from "../../../store/AppContext";

const ListaVendedores = ({ setMesVentaSeleccionado, setIdSeleccionado }) => {
	const { store, actions } = useContext(Context);

	const HandlerClickVendedor = (e) => {
		setIdSeleccionado(e.target.id);
		setMesVentaSeleccionado(true);
	};

	const ListaDesplegarUsuarios = (objeto, i) => {
		return (
			<>
				<button
					className='button warning'
					id={objeto.id}
					onClick={(e) => HandlerClickVendedor(e)}
				>
					{objeto.nombre} - {objeto.mesIngreso} {objeto.yearIngreso}
				</button>
				&nbsp;
			</>
		);
	};

	return (
		<div>
			Lista de ventas de Vendedores, por Mes y/o Año, según selección realizada:
			<br />
			<br />
			{store.ventasUsuario != null &&
				store.ventasUsuario.map((objeto, i) =>
					ListaDesplegarUsuarios(objeto, i)
				)}
		</div>
	);
};

export default ListaVendedores;
