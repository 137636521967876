import "./AlertaFlotante.css";

const Alerta = () => {
	return (
		<div className='alertaFlotante'>
			<div className='warningFlotante alertFlotante'>
				<div className='contentFlotante'>
					<div className='iconFlotante'>&#9762;</div>
					<p>Cambios no guardados.</p>
				</div>
				<small>
					Para guardar la información agregada, debes <br></br>hacer clic en
					alguno de los botones de guardar.
				</small>
			</div>
		</div>
	);
};

export default Alerta;
