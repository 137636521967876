import React, { useContext, useState, useEffect } from "react";
import { Context } from "../../../../store/AppContext";
import Paginador from "../../Paginator.js";
import ListaClientesDtCompleta from "./ListaClientesDtCompleta";
import ClienteSeleccionado from "../../ClienteSeleccionado.js";
import { ExportTableToExcel } from "../../../../Helper/ExportTableToExcel";

/* Busqueda con BD Completa */

const BusquedaCompleta = ({ setBusquedaCompleta, tipoFiltro }) => {
	const { store, actions } = useContext(Context);
	const [clienteSeleccionado, setClienteSeleccionado] = useState(false);
	const [clienteDtCliqueado, setClienteDtCliqueado] = useState(null);
	const [colores, setColores] = useState(false); //Controla la Aplicación de Colores en las tablas.
	const [listaClientes, setListaClientes] = useState(null);

	useEffect(() => {
		if (sessionStorage.getItem("listadoClientesDt") != null) {
			setListaClientes(JSON.parse(sessionStorage.getItem("listadoClientesDt")));
			setTimeout(() => {
				actions.setSpinner(false);
			}, 100);
		} else if (sessionStorage.getItem("listadoClientesDt") === null) {
			actions.getBusquedaDt("", tipoFiltro);
		}

		/* Colores */
		if (localStorage.getItem("colorFiltroDt") != null) {
			if (localStorage.getItem("colorFiltroDt") === "true") {
				setColores(true);
			} else {
				setColores(false);
			}
		}
	}, []);

	const HandlerExportarTabla = (event) => {
		ExportTableToExcel("xlsx");
	};

	const HandlerSwitchColores = (event) => {
		if (colores === false) {
			setColores(true);
			localStorage.setItem("colorFiltroDt", "true");
		} else if (colores === true) {
			setColores(false);
			localStorage.setItem("colorFiltroDt", "false");
		}
	};

	const tituloListado = () => {
		if (sessionStorage.getItem("dateClientesDt") != null) {
			return (
				<>
					<h2>Lista de Clientes DT {tipoFiltro.toString()}s*</h2>
					<small>
						*Lista actualizada hasta el{" "}
						{sessionStorage.getItem("dateClientesDt")}
					</small>
				</>
			);
		}
	};

	return (
		<div>
			<div>
				<div className='no-print row button-group align-right'>
					<button
						className='clear button alert'
						onClick={(e) => setBusquedaCompleta(false)}
					>
						Regresar Panel Inicial
					</button>
					{clienteSeleccionado && (
						<>
							<a class='clear button' href='#' disabled>
								&#62;
							</a>

							<button
								className='clear button alert'
								onClick={(e) => setClienteSeleccionado(false)}
							>
								Regresar Listado Previo
							</button>
						</>
					)}
				</div>
				<>
					{/* Botones de Opciones */}
					<div className='row'>
						{clienteSeleccionado && (
							<ClienteSeleccionado
								setClienteSeleccionado={setClienteSeleccionado}
								clienteDtCliqueado={clienteDtCliqueado}
							/>
						)}
						{!clienteSeleccionado && (
							<>
								{tituloListado()}
								<div className='columns small-12'>
									<div className='columns small-9'>&nbsp;</div>
									<div className='columns small-1'>
										{/* Este es el Switch para la Aplicación de Colores */}
										<div>
											<div className='switch large'>
												<input
													className='switch-input'
													id='yes-noEspecifico'
													type='checkbox'
													name='exampleSwitch'
													checked={colores ? true : false}
												/>
												<label
													className='switch-paddle'
													htmlFor='yes-noEspecifico'
													onClick={(e) => HandlerSwitchColores(e)}
												>
													<span className='show-for-sr'>Colores?</span>
													<span className='switch-active' aria-hidden='true'>
														Si
													</span>
													<span className='switch-inactive' aria-hidden='true'>
														No
													</span>
												</label>
											</div>
										</div>
									</div>
									<div className='columns small-2'>
										<button
											className='submit button expanded'
											onClick={(e) => HandlerExportarTabla(e)}
										>
											Exportar Seleccion
										</button>
									</div>
								</div>
							</>
						)}
					</div>
					<br />
					{!clienteSeleccionado && (
						<>
							<ListaClientesDtCompleta
								setClienteSeleccionado={setClienteSeleccionado}
								setClienteDtCliqueado={setClienteDtCliqueado}
								colores={colores}
								tipoFiltro={tipoFiltro}
							/>
							<div className='' style={{ textAlign: "center" }}>
								<br />
								<Paginador />
							</div>
						</>
					)}
				</>
			</div>
		</div>
	);
};

export default BusquedaCompleta;
