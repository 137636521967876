import React, { Fragment, useContext, useState, useEffect } from "react";
import { Context } from "../../../../store/AppContext";
import Paginador from "../../Paginator.js";
import ListaClientesDt from "./ListaClientesDt";
import ClienteSeleccionado from "../../ClienteSeleccionado.js";
import FormularioClienteDt from "../NuevoCliente/FormularioClienteDt.js";
import FiltroListaClientes from "./FiltroListaClientes";
import Buscador from "./Buscador.js";
import { ExportTableToExcel } from "../../../../Helper/ExportTableToExcel";

const VistaDtUsuarioCobranza = ({ setPantallaAntiguaClientes }) => {
	const { store, actions } = useContext(Context);
	const [clienteSeleccionado, setClienteSeleccionado] = useState(false);
	const [buscando, setBuscando] = useState(false); //Detecta si hemos apretado el botón Buscar o hemos dato Enter
	const [clienteDtBuscado, setClienteDtBuscado] = useState(null);
	const [clienteDtCliqueado, setClienteDtCliqueado] = useState(null);
	const [colores, setColores] = useState(false); //Controla la Aplicación de Colores en las tablas.
	const [filtro, setFiltro] = useState(false);
	const [filtros, setFiltros] = useState({
		vigente: "Selecciona...",
		whatsapp: "Selecciona...",
		erpyme: "Selecciona...",
		p: "Selecciona...",
		sacar: "Selecciona...",
		dicom: "Selecciona...",
		repetido: "Selecciona...",
		tipoPago: "Selecciona...",
	});
	const [saldo, setSaldo] = useState("Selecciona...");

	useEffect(() => {
		actions.getClientesDt(1);
	}, []);

	//Las siguientes funciones son las que permiten algunas acciones básicas en la página.

	const HandlerRecargarPagina = (event) => {
		actions.getClientesDt(1);
	};

	const HandlerExportarTabla = (event) => {
		ExportTableToExcel("xlsx");
	};

	const HandlerFiltro = (event) => {
		if (filtro === false) {
			setFiltro(true);
		} else if (filtro === true) {
			setFiltro(false);
			setFiltros({
				vigente: "Selecciona...",
				whatsapp: "Selecciona...",
				erpyme: "Selecciona...",
				p: "Selecciona...",
				sacar: "Selecciona...",
				dicom: "Selecciona...",
				repetido: "Selecciona...",
				tipoPago: "Selecciona...",
			});
			setSaldo("Selecciona...");
			actions.getClientesDt(1);
		}
	};

	const HandlerBuscar = (event) => {
		actions.getBusquedaDt(clienteDtBuscado);
		buscando ? setBuscando(true) : setBuscando(true);
	};

	//Funcion para Aplicar Colores. P = Todo Pagado, turquesa.  Sacar = red

	const HandlerSwitchColores = (event) => {
		if (colores === false) {
			setColores(true);
		} else if (colores === true) {
			setColores(false);
		}
	};

	return (
		<div className=''>
			<div className='row button-group align-right'>
				<button
					className='button secondary'
					onClick={(e) => setPantallaAntiguaClientes(false)}
				>
					Regresar
				</button>
			</div>
			<div className=''>
				<div className='row'>
					{clienteSeleccionado ? (
						<ClienteSeleccionado
							setClienteSeleccionado={setClienteSeleccionado}
							clienteDtCliqueado={clienteDtCliqueado}
						/>
					) : null}
					{clienteSeleccionado ? null : (
						<Fragment>
							<div className='row'>
								<div
									className='grid-x grid-margin-x'
									style={{
										boxShadow: "0px 4px 8px #000000",
										paddingTop: "20px",
										paddingBottom: "5px",
									}}
								>
									<Buscador setClienteDtBuscado={setClienteDtBuscado} />
									<div className='cell small-1 text-right'>
										<a
											className='clear button secondary'
											onClick={(e) => HandlerBuscar(e)}
										>
											Buscar
										</a>
									</div>
									<div className='cell small-1 text-right'>
										<a
											className='clear button secondary'
											onClick={(e) => HandlerRecargarPagina(e)}
										>
											&#8634;
										</a>
									</div>
								</div>
							</div>
							<br />
							<hr />
							{/* Barra de Botones */}
							<div className='row button-group align-right'>
								{/* Este es el Switch para la Aplicación de Colores */}
								<div style={{ marginRight: "20px" }}>
									<p>+ Colores?</p>
									<div className='switch'>
										<input
											className='switch-input'
											id='yes-no'
											type='checkbox'
											name='exampleSwitch'
										/>
										<label
											className='switch-paddle'
											htmlFor='yes-no'
											onClick={(e) => HandlerSwitchColores(e)}
										>
											<span className='show-for-sr'>Colores?</span>
											<span className='switch-active' aria-hidden='true'>
												Si
											</span>
											<span className='switch-inactive' aria-hidden='true'>
												No
											</span>
										</label>
									</div>
								</div>
								<button
									className='submit button'
									onClick={(e) => HandlerExportarTabla(e)}
								>
									Exportar Seleccion
								</button>
								<button
									className='submit button secondary'
									onClick={(e) => HandlerFiltro(e)}
								>
									Filtrar
								</button>
							</div>
							<div className='' style={{ textAlign: "center" }}>
								<br />
								{filtro ? (
									<FiltroListaClientes
										filtros={filtros}
										setFiltros={setFiltros}
										saldo={saldo}
										setSaldo={setSaldo}
									/>
								) : null}
								<br />
								<Paginador />
							</div>
							<br />
							<ListaClientesDt
								clienteDtBuscado={clienteDtBuscado}
								setClienteSeleccionado={setClienteSeleccionado}
								setClienteDtCliqueado={setClienteDtCliqueado}
								colores={colores}
								saldo={saldo}
							/>
							<div className='' style={{ textAlign: "center" }}>
								<br />
								<Paginador />
							</div>
						</Fragment>
					)}
				</div>
			</div>
		</div>
	);
};

export default VistaDtUsuarioCobranza;
