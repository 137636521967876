import React, { useState } from "react";

const FormularioDocumentosPatente = ({
	datosDocumentosPatente,
	setDatosDocumentosPatente,
}) => {
	const [giro2, setGiro2] = useState(false);
	const [giro3, setGiro3] = useState(false);

	const Handler = (event) => {
		setDatosDocumentosPatente({
			...datosDocumentosPatente,
			[event.target.name]: event.target.value,
		});
	};

	const HandlerClick = (event) => {
		if (event.target.name == "buttonGiro1") {
			setGiro2(true);
		}
		if (event.target.name == "buttonGiro2") {
			setGiro3(true);
		}
	};

	return (
		<div className='row'>
			<div className='columns small-12'>
				<label>
					Nombre Representante*
					<input
						type='text'
						placeholder='Nombre Completo del Representante'
						value={datosDocumentosPatente.nombre}
						name='nombre'
						onChange={(e) => Handler(e)}
					/>
				</label>
				<label>
					Rut de Representante Legal*
					<input
						type='text'
						placeholder='17.123.123-K'
						value={datosDocumentosPatente.rut}
						name='rut'
						onChange={(e) => Handler(e)}
					/>
				</label>
				<label>
					Razón Social Empresa*
					<input
						type='text'
						placeholder='Nombre Completo SpA'
						value={datosDocumentosPatente.razon}
						name='razon'
						onChange={(e) => Handler(e)}
					/>
				</label>
				<label>
					Rut Empresa*
					<input
						type='text'
						placeholder='76.717.904-9'
						value={datosDocumentosPatente.rutEmpresa}
						name='rutEmpresa'
						onChange={(e) => Handler(e)}
					/>
				</label>
				<label>
					Actividad Especifica*
					<input
						type='text'
						placeholder='Prestación de Servicios en Emprendimiento'
						value={datosDocumentosPatente.actividadEspecifica}
						name='actividadEspecifica'
						onChange={(e) => Handler(e)}
					/>
				</label>
				<div className='columns small-12'>
					<label className='columns small-3'>
						Código Actividad 1*
						<input
							type='text'
							placeholder='014602'
							value={datosDocumentosPatente.codigo1}
							name='codigo1'
							onChange={(e) => Handler(e)}
						/>
					</label>
					<label className='columns small-8'>
						Giro Actividad 1*
						<input
							type='text'
							placeholder='CRÍA DE AVES DE CORRAL PARA LA PRODUCCIÓN DE HUEVOS'
							value={datosDocumentosPatente.giro1}
							name='giro1'
							onChange={(e) => Handler(e)}
						/>
					</label>
					<label className='columns small-1'>
						&nbsp;
						<button
							class='button warning'
							style={{ display: "block" }}
							name='buttonGiro1'
							onClick={(e) => HandlerClick(e)}
						>
							+
						</button>
					</label>
				</div>
				{giro2 ? (
					<div className='columns small-12'>
						<label className='columns small-3'>
							Código Actividad 2
							<input
								type='text'
								placeholder='960320'
								value={datosDocumentosPatente.codig21}
								name='codigo2'
								onChange={(e) => Handler(e)}
							/>
						</label>
						<label className='columns small-8'>
							Giro Actividad 2
							<input
								type='text'
								placeholder='SERVICIOS DE CEMENTERIOS'
								value={datosDocumentosPatente.giro2}
								name='giro2'
								onChange={(e) => Handler(e)}
							/>
						</label>
						<label className='columns small-1'>
							&nbsp;
							<button
								class='button warning'
								style={{ display: "block" }}
								name='buttonGiro2'
								onClick={(e) => HandlerClick(e)}
							>
								+
							</button>
						</label>
					</div>
				) : null}
				{giro3 ? (
					<div className='columns small-12'>
						<label className='columns small-3'>
							Código Actividad 3
							<input
								type='text'
								placeholder='591200'
								value={datosDocumentosPatente.codigo3}
								name='codigo3'
								onChange={(e) => Handler(e)}
							/>
						</label>
						<label className='columns small-8'>
							Giro Actividad 3
							<input
								type='text'
								placeholder='ACTIVIDADES DE POSTPRODUCCIÓN DE PELÍCULAS CINEMATOGRÁFICAS, VIDEOS Y PROGRAMAS DE TELEVISIÓN'
								value={datosDocumentosPatente.giro3}
								name='giro3'
								onChange={(e) => Handler(e)}
							/>
						</label>
						<label className='columns small-1'>&nbsp;</label>
					</div>
				) : null}
				<label>
					Capital del Negocio*
					<input
						type='text'
						placeholder='1.000.000'
						value={datosDocumentosPatente.capitalNegocio}
						name='capitalNegocio'
						onChange={(e) => Handler(e)}
					/>
				</label>
				<label>
					Número de Trabajadores del Inmueble*
					<input
						type='text'
						placeholder='1'
						value={datosDocumentosPatente.numeroTrabajadoresInmueble}
						name='numeroTrabajadoresInmueble'
						onChange={(e) => Handler(e)}
					/>
				</label>
				<label>
					Número de Trabajadores total País*
					<input
						type='text'
						placeholder='1'
						value={datosDocumentosPatente.numeroTrabajadoresPais}
						name='numeroTrabajadoresPais'
						onChange={(e) => Handler(e)}
					/>
				</label>
			</div>
		</div>
	);
};

export default FormularioDocumentosPatente;
