import { useContext, useEffect, useState } from "react";
import { Context } from "../../store/AppContext";
import ListaVendedores from "./ModificarIngresoDatos/ListaVendedores";
import OpcionesModificarIngresoDatos from "./ModificarIngresoDatos/OpcionesModificarIngresoDatos";
import FormularioModificarIngresoDatos from "./ModificarIngresoDatos/FormularioModificarIngresoDatos";

const ModificarIngresoDatos = ({ setModificarIngresoDatos }) => {
	const { store, actions } = useContext(Context);
	const [mesIngreso, setMesIngreso] = useState("Selecciona...");
	const [yearIngreso, setYearIngreso] = useState("Selecciona...");
	const [alert, setAlert] = useState(false);
	const [mesVentaSeleccionado, setMesVentaSeleccionado] =
		useState(false); /* Este abre o cierra popup */
	const [idSeleccionado, setIdSeleccionado] = useState(null);

	useEffect(() => {
		actions.getUsuarios();
		actions.setVentaUsuariostoNull();
	}, []);

	const HandlerCerrar = () => {
		setModificarIngresoDatos(false);
	};

	return (
		<div className='row'>
			{!mesVentaSeleccionado && (
				<div className='callout' data-closable>
					<button
						className='close-button no-print'
						aria-label='Dismiss alert'
						type='button'
						data-close
						onClick={(e) => HandlerCerrar(e)}
					>
						<span aria-hidden='true'>×</span>
					</button>
					<br />
					<div className=''>
						<div className='cell small-6'>
							<h3>Modifica Información de Vendedores</h3>
						</div>
					</div>
					<OpcionesModificarIngresoDatos
						yearIngreso={yearIngreso}
						setYearIngreso={setYearIngreso}
						mesIngreso={mesIngreso}
						setMesIngreso={setMesIngreso}
						alert={alert}
						setAlert={setAlert}
					/>
					<hr />
					{store.ventasUsuario != null ? (
						<ListaVendedores
							setMesVentaSeleccionado={setMesVentaSeleccionado}
							setIdSeleccionado={setIdSeleccionado}
						/>
					) : (
						<p>Selecciona para ver Resultados</p>
					)}
				</div>
			)}
			{mesVentaSeleccionado && (
				<FormularioModificarIngresoDatos
					idSeleccionado={idSeleccionado}
					setMesVentaSeleccionado={setMesVentaSeleccionado}
					setModificarIngresoDatos={setModificarIngresoDatos}
				/>
			)}
		</div>
	);
};

export default ModificarIngresoDatos;
